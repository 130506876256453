import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { routePaths } from "../../routes";
import {
  isEmailFromGoogle,
  displayErrorMessage,
  setIntoStorage,
  getFromStorage,
  clearStorage,
  validate, 
  isEmailFromGSuite
} from "../../utils/common";
import SignUpForm from "./SignUpForm";
import SignupHeader from "../../common/components/SignupHeader";
import GoogleRecommend from "../../common/components/GoogleRecommend";
import Loader from "../../common/components/Loader";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import { signUpRequest, generateEmail } from "../../redux/modules/signUp/signUpActions";
import { getPreferences } from "../../redux/modules/preferences/preferencesActions";
import MetaHeader from "../../common/components/MetaHeader";
import StickyFooter from "../../common/components/StickyFooter";
import "./SignUp.scss";

const getKeyBySlug = slug =>
  slug === "google"
    ? "loginbygoogle_url"
    : (slug === "facebook" && "loginbyfacebook_url") || "";

const signupMeta = {
  title: "",
  description: "",
  keywords: "",
  image: ""
};

const loginMeta = {
  title: "",
  description: "",
  keywords: "",
  image: ""
};

const SignUp = props => {
  const {
    location,
    history,
    signUpRequestLoading,
    signUpRequest,
    generateEmail,
    getPreferences,
    preferences,
    getGenerateEmailLoaded,
    prefillEmail
  } = props;
  
  const [isGoogleRecommend, setGoogleRecommendFlag] = useState(false);
  const [email, setSignUpEmail] = useState("");
  const [error, setError] = useState("");
  const pageMeta =
    location.search && location.search.includes("signup")
      ? signupMeta
      : loginMeta;

  useEffect(() => {
    const email = getFromStorage("email");
    if (email == null) {
      const onSuccess = () => {};
      const onFail = () => {};
      generateEmail({ onSuccess, onFail });
    }

    if (email && !validate("email", email)) {
      setSignUpEmail(email);
    }
    if (preferences && !preferences.google_privacy_url) {
      const onSuccess = () => {};
      const onFail = () => {};
      getPreferences({ onSuccess, onFail });
    }
    if (
      !location.search ||
      (location.search &&
        !location.search.includes("signup") &&
        !location.search.includes("login"))
    ) {
      var invite_code = getFromStorage("invite_code") ? getFromStorage("invite_code") : "";
      {invite_code 
        ? history.push(`${routePaths.signUp}?signup`) 
        : history.push(`${routePaths.signUp}?login`)}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const email = getFromStorage("email");
    if (email == null) {
      let changeEmail = prefillEmail && prefillEmail.replace(/torg\.account/g, 'benk.com');
      setSignUpEmail(changeEmail);      
    }
  }, [generateEmail, getGenerateEmailLoaded]);

  const handleSocialLogin = (slug, isIdentify) => {
    clearStorage();
    if (isIdentify) setGoogleRecommendFlag(!isGoogleRecommend);
    const key = getKeyBySlug(slug);
    if (preferences && preferences[key]) {
      window.location.href = preferences[key];
    }
  };

  const onManuallyVerification = React.useCallback(
    emailId => {
      const onSuccess = data => {
        if (data.user) {
          clearStorage();
          setIntoStorage({
            email: data.user.email || "",
            otp_uuid: data.user.otp_uuid || "",
            resendOtpAttempt: 0
          });
          history.push(routePaths.confirmEmail);
        }
      };
      const onFail = err => setError(displayErrorMessage(err));
      signUpRequest({
        data: {
          email: emailId
        },
        onSuccess,
        onFail
      });
    },
    [signUpRequest, history]
  );

  const onSubmit = async (emailId) => {
    if (isEmailFromGoogle(emailId) || await isEmailFromGSuite(emailId)) {
      setSignUpEmail(emailId);
      setGoogleRecommendFlag(prev => !prev);
    } else {
      onManuallyVerification(emailId);
    }
  };

  return (
    <PageLayout divClass="col sign-up">
      <MetaHeader {...pageMeta} />
      {signUpRequestLoading && <Loader />}
      <GoogleRecommend
        signUpRequestLoading={signUpRequestLoading}
        email={email}
        visible={isGoogleRecommend}
        handleGoogleLogin={() => handleSocialLogin("google", true)}
        onClose={() => setGoogleRecommendFlag(!isGoogleRecommend)}
        onManuallyVerification={onManuallyVerification}
      />
      <div>
        <SignupHeader
          title="First, enter your email"
          subTitle="We need it to look up your account or create a new one"
        />
        <SignUpForm
          errorMessage={error}
          preferences={preferences}
          onSubmit={onSubmit}
          handleSocialLogin={handleSocialLogin}
          initialEmail={email}
        />
      </div>
      <div className="fs-12 text-center text-dark-gray mb-150 mt-30">
        Protected by Google{" "}
        <a
          rel="noreferrer"
          className="text-blue link"
          href={preferences.google_privacy_url || ""}
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          rel="noreferrer"
          className="text-blue link"
          href={preferences.google_terms_url || ""}
          target="_blank"
        >
          Terms of Service.
        </a>
      </div>
      <div>
        <StickyFooter
          stickyText="Welcome to the demo version of International Banking project powered by TORG. All data, currencies and amounts are for demo and test only."
        />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    signUpRequestLoading: state.signUpReducer.signUpRequestLoading,
    getGenerateEmailLoaded: state.signUpReducer.getGenerateEmailLoaded,
    preferences: state.preferences.preferences || {},
    prefillEmail: state.signUpReducer.generateEmail
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signUpRequest: payload => dispatch(signUpRequest(payload)),
    getPreferences: payload => dispatch(getPreferences(payload)),
    generateEmail: payload => dispatch(generateEmail(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
