import React from "react";
import clsx from "clsx";
import "./LinkButton.scss";

const LinkButton = React.memo(props => {
  const { text, onClick, styleClass } = props;

  return (
    <span
      className={clsx("custom-link", styleClass ? styleClass : "")}
      onClick={onClick}
    >
      {text}
    </span>
  );
});

export default LinkButton;
