import { handleActions } from "redux-actions";
import { reportMerchantConstants } from "./reportMerchantConstants";
import {
  requestPending,
  requestSuccess,
  requestFail
} from "../../../utils/fetch";
import { initialReportMerchant } from "./initialReportMerchant";

export const reportMerchantReducer = handleActions(
  {
    [requestSuccess(reportMerchantConstants.FETCH_TYPE_OF_ISSUES)]: (
      state,
      action
    ) => ({
      ...state,
      typeOfIssues: action.payload || {},
      fetchTypeOfIssuesLoading: false,
      fetchTypeOfIssuesFailure: false,
      fetchTypeOfIssuesLoaded: true
    }),
    [requestPending(reportMerchantConstants.FETCH_TYPE_OF_ISSUES)]: state => ({
      ...state,
      fetchTypeOfIssuesLoading: true,
      fetchTypeOfIssuesFailure: false,
      fetchTypeOfIssuesLoaded: false
    }),
    [requestFail(reportMerchantConstants.FETCH_TYPE_OF_ISSUES)]: state => ({
      ...state,
      fetchTypeOfIssuesLoading: false,
      fetchTypeOfIssuesFailure: true,
      fetchTypeOfIssuesLoaded: false
    }),
    [requestSuccess(
      reportMerchantConstants.REPORT_MERCHANT_ACTIVITY
    )]: state => ({
      ...state,
      reportMerchantActivityLoading: false,
      reportMerchantActivityFailure: false,
      reportMerchantActivityLoaded: true
    }),
    [requestPending(
      reportMerchantConstants.REPORT_MERCHANT_ACTIVITY
    )]: state => ({
      ...state,
      reportMerchantActivityLoading: true,
      reportMerchantActivityFailure: false,
      reportMerchantActivityLoaded: false
    }),
    [requestFail(reportMerchantConstants.REPORT_MERCHANT_ACTIVITY)]: state => ({
      ...state,
      reportMerchantActivityLoading: false,
      reportMerchantActivityFailure: true,
      reportMerchantActivityLoaded: false
    })
  },
  initialReportMerchant()
);
