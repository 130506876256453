import { createAction } from "redux-actions";
import { InvitationConstants } from "./invitationConstants";

export const fetchInvitationConfig = createAction(
  InvitationConstants.FETCH_INVITE_CONFIG
);

export const sendInvitation = createAction(InvitationConstants.SEND_INVITATION);

export const setInvitedEmails = createAction(
  InvitationConstants.SET_INVITED_EMAILS
);
