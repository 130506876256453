import React from "react";
import OtpInput from "./OtpInput";
import "./CustomizeOtpInput.scss";

const CustomizeOtpInput = props => {
  const {
    value,
    numInputs,
    onChange,
    onKeyDown,
    className,
    firstDigitDoesNotToBeZero
  } = props;
  return (
    <OtpInput
      onChange={onChange}
      numInputs={numInputs}
      containerStyle={`customize-otp-input ${className || ""}`}
      inputStyle="otp-input"
      onKeyPress={onKeyDown}
      shouldAutoFocus
      value={value || null}
      firstDigitDoesNotToBeZero={firstDigitDoesNotToBeZero || false}
    />
  );
};

export default CustomizeOtpInput;
