import { call, takeEvery } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { AccountNumberConstatns } from "./accountNumberConstants";

function* checkIsAccNumberAvailable(action) {
  yield call(
    request({
      type: AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE,
      method: "POST",
      data: action.payload.data,
      url: `user/checkaccountnumber`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* createAccount(action) {
  yield call(
    request({
      type: AccountNumberConstatns.CREATE_ACCOUNT,
      method: "POST",
      data: action.payload.data,
      url: `user/setaccountnumber`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeEvery(
    AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE,
    checkIsAccNumberAvailable
  );
  yield takeEvery(AccountNumberConstatns.CREATE_ACCOUNT, createAccount);
}
