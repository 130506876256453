import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomToolTip from "../CustomToolTip";
import questionIcon from "../../../../assets/images/question.svg";
import closeIcon from "../../../../assets/images/icon-close.svg";
import "./CustomizeInput.scss";

const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#848FA2",
      "&.Mui-error": {
        color: "#ED2939"
      }
    },
    "& label.Mui-focused": {
      color: "#0E8DEA"
    },
    "& .MuiFilledInput-underline": {
      "&:before": {
        borderBottomColor: "#848FA2"
      },
      "&:after": {
        borderBottomColor: "#0E8DEA"
      },
      "&.Mui-error:after": {
        borderBottomColor: "#ED2939",
        borderBottomWidth: 1
      }
    },
    "& .MuiFormHelperText-filled": {
      "&.Mui-error": {
        color: "#ED2939"
      }
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#F4F4F4",
      borderRadius: "5px 5px 0 0"
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0
    }
  }
})(TextField);

const CustomizeInput = props => {
  const {
    label,
    name,
    value,
    onChange,
    placeholder,
    toolTip,
    error,
    inputType,
    maxLength,
    onBlur,
    onFocus,
    onKeyDown,
    hideError,
    startAdornment,
    autoFocus,
    inputRef,
    isClear,
    displayClearIconAlways,
    onClear
  } = props;

  const inputProps = {};
  if (toolTip) {
    inputProps.endAdornment = (
      <CustomToolTip
        title={toolTip.title || ""}
        content={toolTip.content || ""}
      >
        <InputAdornment position="end">
          <img src={toolTip.icon || questionIcon} alt="info" />
        </InputAdornment>
      </CustomToolTip>
    );
  }

  if (startAdornment) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <img src={startAdornment.icon} alt="info" />
      </InputAdornment>
    );
  }

  return (
    <div className="customize-input custom-input">
      <CssTextField
        name={name || ""}
        inputRef={inputRef}
        autoFocus={autoFocus || false}
        error={!!error}
        helperText={hideError ? "" : error}
        label={label}
        value={value}
        onFocus={onFocus || (() => {})}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur || (() => {})}
        onKeyDown={onKeyDown || (() => {})}
        variant="filled"
        InputProps={inputProps}
        inputProps={{ maxLength: maxLength }}
        type={inputType}
        fullWidth
      />
      {(value || displayClearIconAlways) && isClear && (
        <div className="custom-close back-button" onClick={onClear}>
          <img src={closeIcon} width={12} alt="close-icon" />
        </div>
      )}
    </div>
  );
};

export default CustomizeInput;
