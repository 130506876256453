import moment from "moment";
import _ from "lodash";
import WAValidator from "@swyftx/api-crypto-address-validator";
import {
  DATE_FORMAT,
  URL_REGEXP,
  PHONE_REGEXP,
  NAME_REGEXP,
  EMAIL_REGEXP,
  VALID_EMAIL_REGEXP,
  GOOGLE_EMAIL_REGEXP,
  IMAGE_REGEXP,
  INTERNATIONAL_PHONE_REGEXP,
  DATE_FORMAT_dddMMMDD,
  TIME_FORMAT_HHMM,
  WEB_URL_REGEXP,
  REGULAR_PHONE_REGEXP
} from "../constants/constants";
import {
  authRedirect,
  dashboard,
  freeRoutes,
  routePaths,
  signUpRoutes
} from "../routes";

export const isValidWalletAddress = (address, currencyCode) => {
  try {
    const valid = WAValidator.validate(address, currencyCode);
    if (!valid) return "Address is invalid";
    return null;
  } catch (e) {
    return e.message || "Address invalid";
  }
};

export const isEmailFromGoogle = email => {
  email = (email || "").trim().toLowerCase();
  const email_regexp = /^[\w.+\-]+@g(oogle)?mail\.com$/; // eslint-disable-line
  return email_regexp.test(email);
};

export const isEmailFromGSuite = async email => {
  const domain = email.split("@")[1];
  let data1 = [];
  await fetch(`https://dns.google.com/resolve?name=${domain}&type=MX`)
    .then(response => response.json())
    .then(data => {
      data1 =
        (data.Answer &&
          data.Answer.filter(item =>
            item.data.toLowerCase().includes("google")
          )) ||
        [];
    });
  return data1.length > 0;
};

export const setIntoStorage = info => {
  Object.keys(info).forEach(key => {
    localStorage.setItem(key, info[key]);
  });
};

export const getFromStorage = key => {
  return localStorage.getItem(key);
};

export const removeFromStorage = key => {
  return localStorage.removeItem(key);
};

export const clearStorage = () => {
  const email = localStorage.getItem("email");
  const invite_code = getFromStorage("invite_code");
  localStorage.clear();
  localStorage.setItem("email", email);
  if (invite_code) {
    localStorage.setItem("invite_code", invite_code);
  }
};

export const getAuthToken = () => localStorage.getItem("AuthToken");

export const getFromSession = key => {
  return sessionStorage.getItem(key);
};

export const setInToSession = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const clearSession = () => {
  sessionStorage.clear();
};

export const removeFromSession = key => {
  sessionStorage.removeItem(key);
};

export const isDOBValid = inputDate => {
  const date = moment(inputDate, DATE_FORMAT);
  const isInValid = !date.isValid();

  if (isInValid) return "Date of birth is invalid";

  if (date.isAfter(moment()))
    return "It looks like you entered the wrong info. Please be sure to use a real birthday.";

  if (parseInt(date.year()) < 1900)
    return "You must not be more than 120 years old at the time of the Benk account opening.";

  let diffYrs = moment(new Date()).diff(
    moment(new Date(inputDate), DATE_FORMAT),
    "years",
    true
  );
  if (diffYrs < 16) {
    return "You must be at least 16 years old to have a Benk account.\n";
  }
  return null;
};

export const validate = (key, value) => {
  switch (key) {
    case "first_name": {
      const urlRegEx = new RegExp(URL_REGEXP);
      const phoneRegEx = new RegExp(PHONE_REGEXP);
      if (!value) {
        return "What's your first name?";
      } else if (
        value.match(urlRegEx) ||
        value.match(phoneRegEx) ||
        !isNaN(value)
      ) {
        return "First name is invalid";
      }
      return null;
    }
    case "business_name":
      if (!value) {
        return "What's your Business Name?";
      }
      return null;
    case "display_name":
      if (!value) {
        return "Display name is required";
      }
      return null;
    case "industry":
      if (!value) {
        return "Industry is required";
      }
      return null;
    case "type_of_issue":
      if (!value) {
        return "Type of issue is required";
      }
      return null;
    case "confirmation":
      if (!value) {
        return "Confirmation is require";
      }
      return null;
    case "last_name": {
      const urlRegEx = new RegExp(URL_REGEXP);
      const phoneRegEx = new RegExp(PHONE_REGEXP);
      if (!value) {
        return "What's your last name?";
      } else if (
        value.match(urlRegEx) ||
        value.match(phoneRegEx) ||
        !isNaN(value)
      ) {
        return "Last name is invalid";
      }
      return null;
    }
    case "birthday":
      if (!value) {
        return "Please fill in a complete birthday";
      }
      return isDOBValid(value);
    case "nationality":
      if (!value) {
        return "What's your Nationality?";
      }
      return null;
    case "away_for":
      if (!value) {
        return "Away for is required";
      }
      return null;
    case "full_name":
      if (!value) {
        return "Full name is required";
      } else if (!NAME_REGEXP.test(value)) {
        return "The full name is not valid";
      }
      return null;
    case "phone_number": {
      const inPhoneRegEx = new RegExp(INTERNATIONAL_PHONE_REGEXP);
      if (!value) {
        return "Phone number is required";
      } else if (!value.match(inPhoneRegEx)) {
        return "Phone number is invalid";
      }
      return null;
    }
    case "business_phone_number": {
      const inPhoneRegEx = new RegExp(REGULAR_PHONE_REGEXP);
      if (!value) {
        return "Phone number is required";
      } else if (!inPhoneRegEx.test(value)) {
        return "Please enter a valid phone number (in international format) with a + sign at the beginning.";
      }
      return null;
    }
    case "business_website": {
      const urlRegEx = new RegExp(WEB_URL_REGEXP);
      if (!value) {
        return "What's your website?";
      } else if (!value.toLowerCase().match(urlRegEx)) {
        return "Please enter a valid URL. (http://, https://) is required.";
      }
      return null;
    }
    case "email":
      if (!value) {
        return "This is required — you’ll need to enter an email.";
      } else if (isEmailFromGoogle(value) && !GOOGLE_EMAIL_REGEXP.test(value)) {
        return "Are you sure that the address is typed correctly?";
      } else if (!EMAIL_REGEXP.test(value)) {
        return "Are you sure that the address is typed correctly?";
      }
      return null;
    case "walletAddress":
    case "network":
      if (!value) {
        return "This field is required";
      }
      return null;
    case "country":
      if (!value) {
        return "This field is required";
      }
      return null;
    default:
      return null;
  }
};

export const formValidate = data => {
  const errMessages = {};
  Object.keys(data).forEach(key => {
    const errMsg = validate(key, data[key]);
    if (errMsg) {
      errMessages[key] = errMsg;
    }
  });
  return errMessages;
};

export const covertToAccNumberFormat = value => {
  return value
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1  ")
    .trim();
};

export const getDateTime = (date, dateFormat, timeFormat) => {
  return {
    date: moment(new Date(date).toISOString().split("T")[0]).format(dateFormat),
    time: moment(date).format(timeFormat)
  };
};

export const displayErrorMessage = err => {
  try {
    const data = (err && err.data) || {};
    if (data.errors && Object.keys(data.errors).length) {
      return _.flattenDeep(Object.values(data.errors)).join(",");
    }
    return data.message || data.error || "Something went wrong";
  } catch (e) {
    return "Something went wrong";
  }
};

//"Started", "Submitted", "Verified", "Rejected", "Resubmission", "Expired
export const veriffStatus = [
  "Submitted",
  "Verified",
  "Rejected",
  "Resubmission",
  "Expired",
];
export const veriffRejected = ["Rejected", "Resubmission", "Expired"];
export const veriffVerified = "Verified";
export const veriffSubmitted = "Submitted";

export const needVerification = ["waiting", "sanctioned"];
export const waitListUser = "waiting";

const returnUrl = (key, user) => {
  const {
    account_number,
    birthday,
    nationality,
    skipidentify,
    personal_info_step,
    agreeterms,
    wait_bonus,
    user_country_type,
    latest_veriff_status,
    is_verify,
    need_verify_country
  } = user;

  switch (key) {
    case "personal":
      if ((!birthday || !nationality) && personal_info_step === 0) {
        return routePaths.personalInfo;
      }
      return null;
    case "accountNumber":
      if (!account_number) {
        return routePaths.accountNumber;
      }
      return null;
    case "identityVerification": {
      if (latest_veriff_status === null && !skipidentify) {
        return routePaths.identityVerification;
      }
      if (
        (latest_veriff_status &&
          latest_veriff_status !== veriffVerified &&
          !skipidentify) ||
        (!is_verify && need_verify_country)
      ) {
        return routePaths.identityVerification;
      }
      return null;
    }
    case "skip-01":
      if (personal_info_step === 1) {
        return routePaths.skip01;
      }
      return null;
    case "skip-02":
      if (!agreeterms) {
        return routePaths.skip02;
      }
      return null;
    case "claim":
      if (wait_bonus) {
        return routePaths.claimDollars;
      }
      return null;
    case "bankAccount": {
      return dashboard;
    }
    default:
      return null;
  }
};

export const afterSignUpVerificationRedirectTo = user => {
  let redirectTo = "";
  [
    "personal",
    "accountNumber",
    "identityVerification",
    "skip-01",
    "skip-02",
    "claim",
    "bankAccount"
  ].forEach(key => {
    if (!redirectTo) redirectTo = returnUrl(key, user);
  });
  return redirectTo;
};

export const validUserStatus = ["active", "created"];
export const redirectAccordingToUserInfo = (user, isMfa) => {
  if (user && user.user_country_type === waitListUser && user.is_verify) {
    return routePaths.waitList;
  }

  if (
    user &&
    user.status &&
    !validUserStatus.includes(user.status.toLowerCase())
  ) {
    return restrictedAccountURl(
      user.status || "",
      user.reason || "",
      user.restricted_country || "",
      user
    );
  }
  if (isMfa) {
    return routePaths.twoFA;
  }
  if (!getFromStorage("isLoggedIn")) {
    return routePaths.signUp;
  } else {
    if (validUserStatus.includes(user.status.toLowerCase())) {
      const redirectTo = afterSignUpVerificationRedirectTo(user);
      if (
        redirectTo &&
        !signUpRoutes.includes(redirectTo) &&
        !freeRoutes.includes(redirectTo)
      ) {
        redirectToLoginApp();
        return null;
      }
      if (redirectTo) {
        return redirectTo;
      }
    }
  }
  return routePaths.signUp;
};

export const redirectToLoginApp = (redirectURL, userDetails, history) => {
  if (userDetails && userDetails.wait_bonus) {
    history.push(routePaths.claimDollars);
  } else {
    const email = getFromStorage("email");
    const token = getFromStorage("AuthToken");
    const isNewAccount = getFromStorage("isNewAccount");
    let link;
    if(isNewAccount) {
      link = `${authRedirect}?token=${token}&email=${email}&isNewAccount=true&redirecurl=${
      redirectURL ? encodeURI(redirectURL) : ""}`;
    } else {
      link = `${authRedirect}?token=${token}&email=${email}&redirecurl=${
      redirectURL ? encodeURI(redirectURL) : ""}`;
    }
    
    clearSession();
    window.location.href = link;
  }
};

export const restrictedAccountURl = (
  status,
  reason,
  restricted_country,
  user
) => {
  // clearStorage();
  setIntoStorage({ restricted_country });

  if (user) {
    const name = parseUserFullName(user);
    setIntoStorage({ name });
    setIntoStorage({ created_at: user.created_at || null });
  }
  const matchStatus = (status && status.toLowerCase()) || "";
  const matchReason = (reason && reason.toLowerCase()) || "";
  if (matchStatus) {
    return `${routePaths.accountStatus}?status=${matchStatus}${
      matchReason ? `&reason=${matchReason || ""}` : ""
    }`;
  } else {
    return "";
  }
};

export const timeConversion = milliseconds => {
  let day, hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return {
    hour: day ? hour + day * 24 : hour >= 10 ? hour : `0${hour}`,
    minute: minute >= 10 ? minute : `0${minute}`,
    seconds: seconds >= 10 ? seconds : `0${seconds}`
  };
};

export const timeSince = date => {
  date = new Date(date);
  let seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + "yr";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + "month";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + "d";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + "h";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + "m";
  }
  return Math.floor(seconds) + "s";
};

export const numberWithCommas = (value, separator) => {
  const valueInString = value.toString();
  const parts = (valueInString && valueInString.split(".")) || [];
  const beforeDecimal =
    (parts &&
      parts[0] &&
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator || ",")) ||
    "";
  const afterDecimal = (parts && parts[1]) || "";
  if (!beforeDecimal) {
    return "";
  } else if (beforeDecimal && !afterDecimal) {
    return `${beforeDecimal}`;
  }
  return `${beforeDecimal}.${afterDecimal}`;
};

export const formatAmount = (value, separator, precision, maxDigit) => {
  let absoluteValue = value
    .toString()
    .split(".")[0]
    .slice(0, maxDigit || 10);
  absoluteValue = numberWithCommas(absoluteValue || "", separator);
  let decimal = (value.toString() || 0).toString().split(".")[1] || "";
  if (precision) {
    decimal = decimal.slice(0, precision);
  }

  if (absoluteValue && decimal) {
    return absoluteValue + "." + decimal;
  } else if (absoluteValue && !decimal) {
    return (
      absoluteValue +
      (value && value.toString().charAt(value.toString().length - 1) === "."
        ? "."
        : "")
    );
  } else {
    return "";
  }
};

export const filterByKey = (items, search, keys) => {
  let reg_filter = "(?:^|\\s)" + search.toLocaleLowerCase() + "(?:|\\w+)";
  let pattern = new RegExp(reg_filter, "g");

  return items.filter(v => {
    let itemFound = false;
    keys.forEach(filterKey => {
      let matchArr = v[filterKey].toLocaleLowerCase().match(pattern);
      if (matchArr !== null && matchArr.length > 0) {
        itemFound = true;
      }
    });
    return itemFound;
  });
};

export const formatAmountDecimal = amount => {
  let value = !isNaN(Math.abs(amount)) ? Math.abs(amount) : 0;
  let absoluteValue = numberWithCommas(value.toString().split(".")[0] || 0);
  let decimal =
    parseFloat(amount.toString() || 0)
      .toString()
      .split(".")[1] || "00";

  return {
    value: absoluteValue,
    decimal
  };
};

export const formatDateFromTimeStamp = (timestamp, dateFormat) => {
  return moment(new Date(timestamp * 1000).toISOString().split("T")[0]).format(
    dateFormat
  );
};

export const reSendCodeTime = (timeStartFrom, noOfAttempt) => {
  if (noOfAttempt <= 3) {
    return timeStartFrom + 60000;
  }
  if (noOfAttempt <= 12) {
    return timeStartFrom + 120000;
  }
  return null;
};

export const isValidContactSearch = value => {
  if (VALID_EMAIL_REGEXP.test(value)) {
    return true;
  } else if (PHONE_REGEXP.test(value) && value.length === 10) {
    return false;
  } else {
    return false;
  }
};

export const isValidEmail = value => {
  if (VALID_EMAIL_REGEXP.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const returnCategoryCode = category => {
  if (category === "Accounts" || category === "Cash" || category === "ac") {
    return "accounts";
  }
  if (category === "Wealth" || category === "Portfolio" || category === "po") {
    return "portfolio";
  }
  if (category === "Credit" || category === "ca") {
    return "credit";
  }
  return "accounts";
};

export const returnCategoryNameByCode = category => {
  if (category === "accounts") {
    return "Accounts";
  }
  if (category === "portfolio") {
    return "Wealth";
  }
  if (category === "credit") {
    return "Credit";
  }
};

export const redirectByChar = char => {
  if (char && char.toLowerCase() === "a") {
    return "/banking/accounts";
  }
  if (char && char.toLowerCase() === "w") {
    return "/banking/portfolio";
  }
  if (char && char.toLowerCase() === "c") {
    return "/banking/credit";
  }
  if (char && char.toLowerCase() === "con") {
    return routePaths.contactList;
  }
  return dashboard;
};

export const isValidImageFormat = value => value.match(IMAGE_REGEXP);

export const localDateTime = timestamp => {
  const dateTime = moment.unix(timestamp).local();

  return {
    date: dateTime.format(DATE_FORMAT_dddMMMDD),
    time: dateTime.format(TIME_FORMAT_HHMM)
  };
};

export const addRestrictionForAppUrl = (user, history) => {
  if (user) {
    const { account_number, birthday, nationality, agreeterms } = user;
    if (!birthday || !account_number || !nationality || !agreeterms) {
      const redirectTo = redirectAccordingToUserInfo(user);
      history.push(redirectTo);
    }
  }
};

export const waitingListCountryName = code => {
  const mapping = {
    CH: "Switzerland",
    HK: "Hong Kong",
    GB: "United Kingdom",
    SG: "Singapore",
    US: "United States"
  };

  return mapping[code] || null;
};

export const waitingListEmail = code => {
  const mapping = {
    CH: "swiss@neobenk.com",
    HK: "hongkong@neobenk.com",
    GB: "uk@neobenk.com",
    SG: "singapore@neobenk.com",
    US: "usa@neobenk.com"
  };

  return mapping[code] || null;
};

export const parseUserFullName = details => {
  if (details) {
    let name = "";
    if (details.first_name) name += details.first_name;
    if (details.last_name) {
      name = name + " " + details.last_name;
    }
    return name;
  }
  return null;
};
