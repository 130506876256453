export const OTP_INPUT_NUM = 6;
export const ACCOUNT_INPUT_NUM = 8;
export const ACCOUNT_NUMBER_FORMAT = "XXXX  XXXX  XX";
export const DEFAULT_GLOBAL_ACC_PROFILE = "USD";
export const GLOBAL_ACCOUNTS_PER_PAGE = 10;
export const MAX_RESEND_OTP_ATTEMPT = 12;
export const ACCESS_TOKEN_TYPE = "Bearer";
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
export const GOOGLE_EMAIL_REGEXP = /^[a-zA-Z0-9](\.?[a-zA-Z0-9]){1,}@g(oogle)?mail\.com$/; // eslint-disable-line
export const URL_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|https?:|ftp?:|http:?|file:?/gi; // eslint-disable-line
export const WEB_URL_REGEXP = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; // eslint-disable-line
export const NAME_REGEXP = /^([a-zA-Z]+[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,})$/; // eslint-disable-line
export const PHONE_REGEXP = /^\d{10}$/; // eslint-disable-line
export const REGULAR_PHONE_REGEXP = /^\+(?:[0-9]●?){6,14}[0-9]$/; // eslint-disable-line
export const INTERNATIONAL_PHONE_REGEXP = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/; // eslint-disable-line
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_MMddyyyy = "MM/dd/yyyy";
export const DATE_FORMAT_dddMMMDD = "dddd, MMM DD";
export const DATE_FORMAT_ddMMMyyyy = "DD MMM yyyy";
export const TIME_FORMAT_HHMM = "HH:mm";
export const PASS_BASE_KEY =
  "owNT9tu06MihwdyvMKLTkzq3Rxmfgaexo3dOgdxK5kfmfGqZNK236MYuKSIZuRH5";
export const VALID_EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
export const IMAGE_REGEXP = /\.(jpg|jpeg|png)$/ // eslint-disable-line

export const MONTHS = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" } // eslint-disable-line
];
export const EMAIL_DOMAINS = [
  "Gmail.com",
  "Yahoo.com",
  "Outlook.com",
  "Aol.com",
];

export const banAccounts = [
  "/AccountLocked",
  "/TemporarilySuspended",
  "/PermanentlySuspended",
  "/TemporarilySuspendedUsPerson",
  "/UseAnotherDevice"
];
