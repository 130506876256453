import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LinkButton from "../../common/components/form/LinkButton";
import Button from "../../common/components/form/Button/Button";
import SignupHeader from "../../common/components/SignupHeader";
import StickyFooter from "../../common/components/StickyFooter";
import ShieldIcon from "../../assets/images/right-shield-white.svg";
import UserAvatar from "../../assets/images/user-avatar-lg-dark.svg";
import PersonalInfoForm from "./components/PersonalInfoForm";
import { displayErrorMessage, formValidate } from "../../utils/common";
import {
  postPersonalInfo,
  uploadProfileImage
} from "../../redux/modules/personalInfo/personalInfoActions";
import Loader from "../../common/components/Loader";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import { routePaths } from "../../routes";
import "./PersonalInfo.scss";
import { config } from "../../utils/config";

const PersonalInfo = React.memo(props => {
  const {
    history,
    postProfileImage,
    postPersonalInfo,
    getUserDetails,
    userDetails,
    personalInfoSubmitted
  } = props;
  const inputFileRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [userImage, setUserImage] = React.useState(null);
  const [imageErr, setImageErr] = React.useState("");
  const [formError, setFormError] = React.useState({});
  const [defaultData, setDefaultData] = React.useState(null);
  const [personalInfo, setPersonalInfo] = React.useState({
    first_name: "",
    last_name: "",
    birthday: "",
    nationality: ""
  });

  React.useEffect(() => {
    if (personalInfoSubmitted) {
      history.push(routePaths.accountNumber);
    }
  }, [personalInfoSubmitted, history]);

  React.useEffect(() => {
    if (!userDetails) {
      getUserDetails();
    } else {
      setDefaultData({
        first_name: userDetails.first_name ? userDetails.first_name.trim() : "",
        last_name: userDetails.last_name ? userDetails.last_name.trim() : "",
        nationality: userDetails.nationality
          ? userDetails.nationality
          : userDetails.user_ip_location
          ? userDetails.user_ip_location.toLowerCase()
          : "",
        birthday: userDetails.birthday || ""
      });
      setPersonalInfo({
        first_name: userDetails.first_name ? userDetails.first_name.trim() : "",
        last_name: userDetails.last_name ? userDetails.last_name.trim() : "",
        nationality: userDetails.nationality
          ? userDetails.nationality
          : userDetails.user_ip_location
          ? userDetails.user_ip_location.toLowerCase()
          : "",
        birthday: userDetails.birthday || ""
      });
      setUserImage(userDetails.picture || null);
    }
  }, [
    getUserDetails,
    userDetails,
    setDefaultData,
    setUserImage,
    setPersonalInfo
  ]);

  const handleFileUploadClick = React.useCallback(() => {
    inputFileRef.current.click();
  }, [inputFileRef]);

  const handleFileUploadChange = React.useCallback(
    e => {
      setImageErr("");
      const imageFile = e.target.files;
      if (imageFile && imageFile.length > 0) {
        if (imageFile[0].name.match(/\.(jpg|jpeg|png)$/)) {
          setLoading(true);
          const fd = new FormData();
          fd.append("UploadForm[image]", imageFile[0], imageFile[0].name);

          const onSuccess = () => {
            setUserImage(URL.createObjectURL(imageFile[0]));
            setLoading(false);
          };
          const onFail = err => {
            setImageErr(displayErrorMessage(err));
            setLoading(false);
          };
          postProfileImage({ data: fd, onSuccess, onFail });
        } else {
          setImageErr(
            "Uploaded file is not a valid image. Only JPG, and PNG files are allowed."
          );
        }
      }
    },
    [setUserImage, setImageErr, postProfileImage, setLoading]
  );

  const handleFormChange = React.useCallback(
    data => {
      setPersonalInfo(data);
    },
    [setPersonalInfo]
  );

  const handleFormSubmit = React.useCallback(() => {
    const formErr = formValidate(personalInfo);
    setFormError(formErr);
    if (_.isEmpty(formErr)) {
      setLoading(true);
      setError("");
      const onSuccess = () => {
        setLoading(false);
        getUserDetails();
        history.push(routePaths.accountNumber);
      };
      const onFail = err => {
        setError(displayErrorMessage(err));
        setLoading(false);
      };
      postPersonalInfo({ data: personalInfo, onFail, onSuccess });
    }
  }, [
    setFormError,
    personalInfo,
    postPersonalInfo,
    setLoading,
    setError,
    history,
    getUserDetails
  ]);

  const addDefaultAvatar = ev => {
    ev.target.src = UserAvatar;
  };

  return (
    <div className="personal-info-container container-pad">
      {loading && <Loader />}
      <div className="header-container">
        <SignupHeader
          title="Personal information"
          subTitle="Benk is required by law to collect this information."
        />
        <div className="user-image-upload">
          <input
            type="file"
            ref={inputFileRef}
            className="d-none"
            onChange={handleFileUploadChange}
          />
          <span className="icon-link" onClick={handleFileUploadClick}>
            <img
              src={userImage ? userImage : UserAvatar}
              alt="User Logo"
              className="user-image"
              onError={addDefaultAvatar}
            />
          </span>
          {imageErr && <span className="error-text mt-1">{imageErr}</span>}
          <LinkButton
            text="Profile picture"
            onClick={handleFileUploadClick}
            styleClass="upload-link"
          />
        </div>
        <div className="personal-info-form">
          <PersonalInfoForm
            isConformScreen={false}
            onChange={handleFormChange}
            errors={formError}
            setErrors={setFormError}
            defaultFormData={defaultData}
          />
        </div>
      </div>
      <div className="footer-container">
        {error && <div className="error-text">{error}</div>}
        <div className="signup-footer-top-text">
          This information will be verified using the Trusted Identity
          Verification services,{" "}
          <LinkButton
            text="Veriff"
            onClick={() => window.open(config.VERIFF_URL, "_blank")}
            styleClass="link-btn"
          />
          , and/or{" "}
          <LinkButton
            text="Onfido"
            onClick={() => window.open(config.ONFIDO_URL, "_blank")}
            styleClass="link-btn"
          />
          .
        </div>
        <div className="btn-container mb-50">
          <Button
            prependIcon={<img src={ShieldIcon} alt="Shield icon" />}
            text="Continue"
            onClick={handleFormSubmit}
          />
        </div>
        {/*<div className="signup-footer-end-text mt-20 text-center">
          By proceeding, you agree to the{" "}
          <LinkButton
            text="Terms of Service"
            onClick={() => {}}
            styleClass="link-btn"
          />{" "}
          and{" "}
          <LinkButton
            text="Privacy Notice"
            onClick={() => {}}
            styleClass="link-btn"
          />
          . See the{" "}
          <LinkButton
            text="Restrictions"
            onClick={() => {}}
            styleClass="link-btn"
          />
          .
        </div>*/}
      </div>
      <StickyFooter
        stickyText=" Welcome to the demo version of International Banking project powered by Opermont. All data, currencies and amounts are for demo and test only."
      />
    </div>

  );
});

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails.details,
    personalInfoSubmitted: state.personalInfo.personalInfoSubmitted
  };
};
const mapDispatchToProps = dispatch => {
  return {
    postProfileImage: payload => dispatch(uploadProfileImage(payload)),
    postPersonalInfo: payload => dispatch(postPersonalInfo(payload)),
    getUserDetails: () => dispatch(fetchUserDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
