import React from "react";
import { connect } from "react-redux";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import HeaderLogo from "../../assets/images/BenkLogo.png";
import WaitListImg from "../../assets/images/wait-list-img.svg";
import { parseUserFullName, waitingListCountryName, waitingListEmail } from "../../utils/common";

const WaitList = React.memo(props => {
  const { userDetails } = props;
  const name = "Indaril Sinha";
  const country = "Switzerland";
  const email = "swiss@neobenk.com";
  return (
    <PageLayout divClass="container-pad d-flex flex-column text-center account-status">
      <div>
        <img style={{width:"100px"}} src={HeaderLogo} alt="Header Logo" />
        <div className="mt-50">
          <img src={WaitListImg} width={99} height={99} alt="Status" />
        </div>
        <div className="mt-20 font-wotfard fw-600 fs-28 text-black">
          Dear {parseUserFullName(userDetails)}
        </div>
        <div className="mt-10 fw-500 fw-18 text-black">
          You’re on the waitlist. We’ll let you know when Benk starts
          providing service in {waitingListCountryName(userDetails.nationality.toUpperCase())}.
        </div>
      </div>
      <div className="fs-15 mb-30">
        <div className="text-black">For further information please contact</div>
        <div className="text-blue">{waitingListEmail(userDetails.nationality.toUpperCase())}</div>
      </div>
    </PageLayout>
  );
});

const mapStateToProps = state => {
  return {
    userDetails:
      state.userDetails.details ||
      (state.signUpReducer.userDetails &&
        (state.signUpReducer.userDetails.user || {}))
  };
};

export default connect(mapStateToProps, null)(WaitList);
