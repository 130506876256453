import React from "react";
import NumberValue from "../../../Transactions/Components/NumberValue";
import UserAvatar from "../../../../common/components/AppLayout/UserAvatar";

const ReceivedMoneyList = props => {
  const { receiveMoneyList } = props;
  return (
    receiveMoneyList &&
    receiveMoneyList.map((item, index) => {
      return (
        <div
          className="d-flex justify-content-between received-money-list animation-card mb-35"
          style={{ animationDelay: `${index === 0 ? 0.6 : index * 3.3}s` }}
          key={index}
        >
          <div className="d-inline-block mr-15">
            <UserAvatar
              image={item && item.contact && item.contact.picture}
              style={{
                width: 41,
                height: 41,
                marginTop: 3
              }}
            />
          </div>
          <div className="d-inline-block w-100">
            <div>
              <div className="d-inline fs-15 text-dark-blue fw-500">
                {`${item.contact.first_name || ""} ${item.contact.last_name ||
                  ""}`}
              </div>
              <div className="d-inline float-right fs-14 fw-500 text-gray">
                +
                <NumberValue
                  mainClass="text-gray"
                  currency={item.receiver_currency || {}}
                  value={item.amount || ""}
                />
              </div>
            </div>
            <div className="mt-1 text-light-gray fs-13">
              <span>{item.date || "Just now"}</span>
              <span className="dot-separator text-blue">
                {item.formal_type}
              </span>
            </div>
          </div>
        </div>
      );
    })
  );
};

export default ReceivedMoneyList;
