import { handleActions } from "redux-actions";
import { initialInvitationState } from "./initialInvitationState";
import { InvitationConstants } from "./invitationConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const invitationReducer = handleActions(
  {
    [requestSuccess(InvitationConstants.FETCH_INVITE_CONFIG)]: (
      state,
      action
    ) => ({
      ...state,
      details: (action.payload && { ...action.payload }) || null,
      fetchInviteConfigLoading: false,
      fetchInviteConfigFailure: false,
      fetchInviteConfigLoaded: true
    }),
    [requestPending(InvitationConstants.FETCH_INVITE_CONFIG)]: state => ({
      ...state,
      fetchInviteConfigLoading: true,
      fetchInviteConfigLoaded: false,
      fetchInviteConfigFailure: false
    }),
    [requestFail(InvitationConstants.FETCH_INVITE_CONFIG)]: state => ({
      ...state,
      fetchInviteConfigLoading: false,
      fetchInviteConfigLoaded: true,
      fetchInviteConfigFailure: true
    }),

    [requestSuccess(InvitationConstants.SEND_INVITATION)]: state => ({
      ...state,
      sendInvitationLoading: false,
      sendInvitationFailure: false,
      sendInvitationLoaded: true
    }),
    [requestPending(InvitationConstants.SEND_INVITATION)]: state => ({
      ...state,
      sendInvitationLoading: true,
      sendInvitationFailure: false,
      sendInvitationLoaded: false
    }),
    [requestFail(InvitationConstants.SEND_INVITATION)]: state => ({
      ...state,
      sendInvitationLoading: false,
      sendInvitationFailure: true,
      sendInvitationLoaded: true
    }),

    [InvitationConstants.SET_INVITED_EMAILS]: (state, action) => ({
      ...state,
      invitedEmails: action.payload || null
    })
  },
  initialInvitationState()
);
