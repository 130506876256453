import { createAction } from "redux-actions";

import { SignUpConstants } from "./signUpConstants";

export const getSocialRedirectLinkRequest = createAction(
  SignUpConstants.SOCIAL_SIGNUP_REQUEST
);
export const signUpRequest = createAction(SignUpConstants.SIGNUP_REQUEST);
export const verifyEmailOTP = createAction(SignUpConstants.VERIFY_EMAIL_OTP);
export const mfaVerifyOTP = createAction(SignUpConstants.MFA_OTP_VERIFY);
export const resendOTPRequest = createAction(
  SignUpConstants.RESEND_OTP_REQUEST
);
export const putSignUpUserDetails = createAction(
  SignUpConstants.UPDATE_SIGNUP_USER
);
export const fetchSignUpProfile = createAction(
  SignUpConstants.FETCH_SIGNUP_PROFILE
);

export const storeAgreeAndContinue = createAction(
  SignUpConstants.STORE_AGREE_AND_CONTINUE
);

export const submitSkip01 = createAction(SignUpConstants.SUBMIT_SKIP_01);
export const cancelSignup = createAction(SignUpConstants.CANCEL_SIGNUP);
export const generateEmail = createAction(SignUpConstants.GENERATE_EMAIL);
