import React from "react";
import FlatInput from "./FlatInput";

const FlatOtpInput = props => {
  let { value, maxLength, onChange, className } = props;

  const handleOnChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length > 7) return;
    const lastChar = inputValue.charAt((inputValue || "").length - 1);
    const isValid = lastChar.replace(/[^0-9]/g, "");
    if (inputValue.length < value.length) {
      value = value.substring(0, value.length - 1);
      onChange(value);
      return;
    }
    if (lastChar !== "*" && isValid) {
      let newValue = `${value}${lastChar}`;
      onChange(newValue);
    }
  };

  const handleOnPaste = e => {
    let inputValue = e.clipboardData.getData("text/plain");
    if (inputValue) inputValue = inputValue.replace(/[^0-9]/g, "");
    if (!inputValue) return;
    onChange(inputValue);
  };

  return (
    <FlatInput
      maxLength={maxLength}
      value={value.replace(/./g, "*")}
      onPaste={handleOnPaste}
      onChange={handleOnChange}
      inputType="tel"
      className={`flat-otp-input ${className}`}
    />
  );
};

export default FlatOtpInput;
