import { handleActions } from "redux-actions";
import { SignUpConstants } from "./signUpConstants";

import {
  requestPending,
  requestSuccess,
  requestFail
} from "../../../utils/fetch";
import { initialSignUpState } from "./initialSignUpState";

export const signUpReducer = handleActions(
  {
    // Page Redux datas
    [requestSuccess(SignUpConstants.SOCIAL_SIGNUP_REQUEST)]: (
      state,
      action
    ) => ({
      ...state,
      socialRedirectLink:
        (action.payload.message && action.payload.message) || "",
      getSocialRedirectRequestLoading: false,
      getSocialRedirectRequestFailure: false,
      getSocialRedirectRequestLoaded: true
    }),
    [requestPending(SignUpConstants.SOCIAL_SIGNUP_REQUEST)]: state => ({
      ...state,
      getSocialRedirectRequestLoading: true,
      getSocialRedirectRequestLoaded: false,
      getSocialRedirectRequestFailure: false
    }),
    [requestFail(SignUpConstants.SOCIAL_SIGNUP_REQUEST)]: state => ({
      ...state,
      getSocialRedirectRequestLoading: false,
      getSocialRedirectRequestLoaded: true,
      getSocialRedirectRequestFailure: true
    }),

    [requestSuccess(SignUpConstants.SIGNUP_REQUEST)]: (state, action) => ({
      ...state,
      userDetails: action.payload.data || {},
      signUpRequestLoading: false,
      signUpRequestFailure: false,
      signUpRequestLoaded: true
    }),
    [requestPending(SignUpConstants.SIGNUP_REQUEST)]: state => ({
      ...state,
      signUpRequestLoading: true,
      signUpRequestLoaded: false,
      signUpRequestFailure: false
    }),
    [requestFail(SignUpConstants.SIGNUP_REQUEST)]: state => ({
      ...state,
      signUpRequestLoading: false,
      signUpRequestLoaded: true,
      signUpRequestFailure: true
    }),
    [SignUpConstants.UPDATE_SIGNUP_USER]: (state, action) => ({
      ...state,
      emailOtpVerifyFailure: false,
      userDetails: {
        ...state.userDetails,
        ...action.payload
      }
    }),

    [requestSuccess(SignUpConstants.VERIFY_EMAIL_OTP)]: (state, action) => ({
      ...state,
      userDetails: (action.payload && action.payload) || {},
      emailOtpVerifyLoading: false,
      emailOtpVerifyFailure: false,
      emailOtpVerifyLoaded: true
    }),
    [requestPending(SignUpConstants.VERIFY_EMAIL_OTP)]: state => ({
      ...state,
      emailOtpVerifyLoading: true,
      emailOtpVerifyLoaded: false,
      emailOtpVerifyFailure: false
    }),
    [requestFail(SignUpConstants.VERIFY_EMAIL_OTP)]: state => ({
      ...state,
      emailOtpVerifyLoading: false,
      emailOtpVerifyLoaded: true,
      emailOtpVerifyFailure: true
    }),

    [requestSuccess(SignUpConstants.MFA_OTP_VERIFY)]: (state, action) => ({
      ...state,
      userDetails: (action.payload && action.payload) || {},
      mfaOtpVerifyLoading: false,
      mfaOtpVerifyFailure: false,
      mfaOtpVerifyLoaded: true
    }),
    [requestPending(SignUpConstants.MFA_OTP_VERIFY)]: state => ({
      ...state,
      mfaOtpVerifyLoading: true,
      mfaOtpVerifyLoaded: false,
      mfaOtpVerifyFailure: false
    }),
    [requestFail(SignUpConstants.MFA_OTP_VERIFY)]: state => ({
      ...state,
      mfaOtpVerifyLoading: false,
      mfaOtpVerifyLoaded: true,
      mfaOtpVerifyFailure: true
    }),

    [requestSuccess(SignUpConstants.RESEND_OTP_REQUEST)]: state => ({
      ...state,
      resendOtpRequestLoading: false,
      resendOtpRequestFailure: false,
      resendOtpRequestLoaded: true
    }),
    [requestPending(SignUpConstants.RESEND_OTP_REQUEST)]: state => ({
      ...state,
      resendOtpRequestLoading: true,
      resendOtpRequestLoaded: false,
      resendOtpRequestFailure: false
    }),
    [requestFail(SignUpConstants.RESEND_OTP_REQUEST)]: state => ({
      ...state,
      resendOtpRequestLoading: false,
      resendOtpRequestLoaded: true,
      resendOtpRequestFailure: true
    }),

    [requestSuccess(SignUpConstants.FETCH_SIGNUP_PROFILE)]: (
      state,
      action
    ) => ({
      ...state,
      userDetails: action.payload.data
        ? { ...state.userDetails, user: action.payload.data }
        : {},
      fetchSignUpProfileLoading: false,
      fetchSignUpProfileFailure: false,
      fetchSignUpProfileLoaded: true
    }),
    [requestPending(SignUpConstants.FETCH_SIGNUP_PROFILE)]: state => ({
      ...state,
      fetchSignUpProfileLoading: true,
      fetchSignUpProfileLoaded: false,
      fetchSignUpProfileFailure: false
    }),
    [requestFail(SignUpConstants.FETCH_SIGNUP_PROFILE)]: state => ({
      ...state,
      fetchSignUpProfileLoading: false,
      fetchSignUpProfileLoaded: true,
      fetchSignUpProfileFailure: true
    }),

    [requestSuccess(SignUpConstants.STORE_AGREE_AND_CONTINUE)]: (
      state,
      action
    ) => ({
      ...state,
      agreeTerms: action.payload,
      storeAgreeAndContinueLoading: false,
      storeAgreeAndContinueFailure: false,
      storeAgreeAndContinueLoaded: true
    }),
    [requestPending(SignUpConstants.STORE_AGREE_AND_CONTINUE)]: state => ({
      ...state,
      storeAgreeAndContinueLoading: true,
      storeAgreeAndContinueFailure: false,
      storeAgreeAndContinueLoaded: false
    }),
    [requestFail(SignUpConstants.STORE_AGREE_AND_CONTINUE)]: state => ({
      ...state,
      storeAgreeAndContinueLoading: false,
      storeAgreeAndContinueFailure: true,
      storeAgreeAndContinueLoaded: true
    }),
    [requestSuccess(SignUpConstants.CANCEL_SIGNUP)]: state => ({
      ...state,
      cancelSignUpLoading: false,
      cancelSignUpFailure: false,
      cancelSignUpLoaded: true
    }),
    [requestPending(SignUpConstants.CANCEL_SIGNUP)]: state => ({
      ...state,
      cancelSignUpLoading: true,
      cancelSignUpFailure: false,
      cancelSignUpLoaded: false
    }),
    [requestFail(SignUpConstants.CANCEL_SIGNUP)]: state => ({
      ...state,
      cancelSignUpLoading: false,
      cancelSignUpFailure: true,
      cancelSignUpLoaded: true
    }),
    [SignUpConstants.SUBMIT_SKIP_01]: state => ({
      ...state,
      submitSkip01: true
    }),

    [requestSuccess(SignUpConstants.GENERATE_EMAIL)]: (state, action) => ({
      ...state,
      generateEmail: action.payload?.email,
      getGenerateEmailLoading: false,
      getGenerateEmailFailure: false,
      getGenerateEmailLoaded: true
    }),
    [requestPending(SignUpConstants.GENERATE_EMAIL)]: state => ({
      ...state,
      getGenerateEmailLoading: true,
      getGenerateEmailFailure: false,
      getGenerateEmailLoaded: false
    }),
    [requestFail(SignUpConstants.GENERATE_EMAIL)]: state => ({
      ...state,
      getGenerateEmailLoading: false,
      getGenerateEmailFailure: true,
      getGenerateEmailLoaded: true
    }),
  },
  initialSignUpState()
);
