import { createAction } from "redux-actions";
import { identityVerificationConstants } from "./identityVerificationConstants";

export const getUserInfo = createAction(
  identityVerificationConstants.GET_USER_INFO
);

export const fatchVeriffUrl = createAction(identityVerificationConstants.GET_VERIFF_URL);

export const setIdentitySubmitted = createAction(
  identityVerificationConstants.SET_IDENTITY_SUBMITTED
);
export const setIdentitySkip = createAction(
  identityVerificationConstants.SET_IDENTITY_SKIP
);
export const setSkipIdentity = createAction(identityVerificationConstants.SET_SKIP_IDENTITY);
export const clearVeriffSession = createAction(identityVerificationConstants.CLEAR_VERIFF_SESSION);
