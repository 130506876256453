import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { PreferencesConstants } from "./preferencesConstant";

function* getPreferences(action) {
  yield call(
    request({
      type: PreferencesConstants.FETCH_PREFERENCES,
      method: "GET",
      url: `auth/config`,
      success:
        action.payload && action.payload.onSuccess
          ? action.payload.onSuccess
          : null,
      fail:
        action.payload && action.payload.onFail ? action.payload.onFail : null
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(PreferencesConstants.FETCH_PREFERENCES, getPreferences);
}
