const keyMirror = require("keymirror");

export const SignUpConstants = keyMirror({
  SOCIAL_SIGNUP_REQUEST: undefined,
  SIGNUP_REQUEST: undefined,
  VERIFY_EMAIL_OTP: undefined,
  MFA_OTP_VERIFY: undefined,
  RESEND_OTP_REQUEST: undefined,
  UPDATE_SIGNUP_USER: undefined,
  FETCH_SIGNUP_PROFILE: undefined,
  STORE_AGREE_AND_CONTINUE: undefined,
  CANCEL_SIGNUP: undefined,
  SUBMIT_SKIP_01: undefined,
  GENERATE_EMAIL: undefined,
});
