import React from "react";
import "./CheckBoxInput.scss";

const CheckBoxInput = props => {
  return (
    <label className={`customize-checkbox-input ${props.className || ""}`}>
      <input type="checkbox" {...props} /> {props.label}
      <span className="checkMark" />
    </label>
  );
};

export default CheckBoxInput;
