import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { CommonConstants } from "./commonConstants";

function* getCountries(action) {
  yield call(
    request({
      type: CommonConstants.FETCH_COUNTRIES,
      method: "GET",
      url: `info/countries`
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(CommonConstants.FETCH_COUNTRIES, getCountries);
}
