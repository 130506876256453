import React from "react";
import WGoogle from "../../../assets/images/google-white.svg";
import CustomizeModal from "../CustomizeModal";
import Button from "../form/Button";
import Loader from "../Loader";
import "./GoogleRecommend.scss";

const GoogleRecommend = props => {
  const {
    visible,
    email,
    onManuallyVerification,
    handleGoogleLogin,
    signUpRequestLoading
  } = props;
  return (
    <CustomizeModal visible={visible} onClose={() => {}}>
      <div className="google-recommend">
        {signUpRequestLoading && <Loader />}
        <div className="text-center">
          <h6 className="title">
            Continue with Google <br /> for a better experience
          </h6>
        </div>
        <div className="font-roboto fs-15 text-center mt-30 text-black fw-normal">
          We will not share your financial data with Google.
        </div>
        <div className="mt-35">
          <Button
            variant="primary"
            text="Continue with Google"
            prependIcon={<img src={WGoogle} alt="Google" width={25} />}
            onClick={handleGoogleLogin}
          />
        </div>
        <div className="mt-20 mb-15 fs-13 text-center text-dark-gray">or</div>
        <div
          className="text-center cursor-pointer"
          onClick={() => onManuallyVerification(email)}
        >
          <span className="link">Verify email address manually</span>
        </div>
      </div>
    </CustomizeModal>
  );
};

export default GoogleRecommend;
