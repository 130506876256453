import React from "react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import "./CustomizeModal.scss";

const CustomizeModal = props => {
  const { visible, onClose, style, className, backdropClassName } = props;
  return (
    <Modal
      backdropClassName={backdropClassName || ""}
      className={clsx("customize-modal", className)}
      show={visible}
      onHide={onClose}
      centered
      style={style ? style : {}}
    >
      {props.children}
    </Modal>
  );
};

export default CustomizeModal;
