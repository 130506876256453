import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const style = () =>
  createStyles({
    container: {
      width: "100%"
    },
    componentRoot: {
      "& label": {
        color: "#848FA2",
        "&.Mui-error": {
          color: "#ED2939"
        }
      },
      "& label.Mui-focused": {
        color: "#0E8DEA"
      },
      "& .MuiFilledInput-underline": {
        "&:after": {
          borderBottomColor: "#0E8DEA"
        },
        "&.Mui-error:after": {
          borderBottomColor: "#ED2939"
        }
      },
      "& .MuiFormHelperText-filled": {
        "&.Mui-error": {
          color: "#ED2939"
        }
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "#F4F4F4",
        borderRadius: "5px 5px 0 0"
      },
      "& .MuiSelect-select": {
        "&:focus": {
          backgroundColor: "#F4F4F4"
        }
      }
    }
  });

const CustomizedSelectInput = React.memo(props => {
  const { classes, label, items, onChange, value, error } = props;

  return (
    <FormControl
      variant="filled"
      className={classes.container}
      classes={{ root: classes.componentRoot }}
      error={!!error}
    >
      <InputLabel>{label}</InputLabel>
      <Select onChange={onChange} value={value}>
        {items &&
          items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
});

export default withStyles(style)(CustomizedSelectInput);
