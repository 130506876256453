import React from "react";
import { Badge, Avatar } from "antd";
import verifiedIcon from "../../../../assets/images/verified.svg";
import "./UserAvatar.scss";

const UserAvatar = ({
  mainClass,
  image,
  className,
  verified,
  verifiedIconWidth,
  superIcon,
  hoverText,
  style,
  appendIcon
}) => {
  const props = {};
  if (verified) {
    props.count = (
      <img
        src={superIcon || verifiedIcon}
        alt="verified"
        width={verifiedIconWidth || 14}
      />
    );
  }
  return (
    <span className={`cursor-pointer user-avatar ${mainClass || ""}`}>
      <Badge {...props}>
        <Avatar
          className={className || ""}
          src={image || ""}
          style={style || ""}
        />
        {appendIcon && <span className="append-icon">{appendIcon}</span>}
        {hoverText ? (
          <div className="image-onText fs-11 fw-900">{hoverText}</div>
        ) : null}
      </Badge>
    </span>
  );
};

export default UserAvatar;
