import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { reportMerchantConstants } from "./reportMerchantConstants";

function* getTypeOfIssues(action) {
  yield call(
    request({
      type: reportMerchantConstants.FETCH_TYPE_OF_ISSUES,
      method: "POST",
      url: "",
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* reportMerchantActivity(action) {
  yield call(
    request({
      type: reportMerchantConstants.REPORT_MERCHANT_ACTIVITY,
      method: "POST",
      url: "",
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(
    reportMerchantConstants.FETCH_TYPE_OF_ISSUES,
    getTypeOfIssues
  );
  yield takeLatest(
    reportMerchantConstants.REPORT_MERCHANT_ACTIVITY,
    reportMerchantActivity
  );
}
