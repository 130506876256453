import AccountLockIcon from "../../assets/images/authstatus/account-locked.svg";
import tempSuspendedIcon from "../../assets/images/authstatus/temp-suspended.svg";
import perSuspendedIcon from "../../assets/images/authstatus/permanent-suspended.svg";
import tempSuspendedUsIcon from "../../assets/images/authstatus/temp-suspended-us.svg";
import useAnotherDeviceIcon from "../../assets/images/authstatus/use-another-device.svg";
import { config } from "../../utils/config";

export const deActivateStatusData = {
  accountlocked: {
    image: AccountLockIcon,
    title: "Your account has been Locked",
    details: [
      "In order to create a Benk account, you must be at least 16 years old. Benk  has determined that you don’t meet this age requirements, so your account has been locked and will be removed from Benk.",
      "If you think it was locked in error, you can\nlet us know."
    ],
    button: {
      text: "Contact support",
      link: ""
    }
  },
  temporarilysuspended: {
    image: tempSuspendedIcon,
    title: "Detecting suspicious activity on your account",
    details: [
      "We want to confirm that the information in your Benk account is accurate as we've detected suspicious activity on your account. To understand this better, we will require some information from you.",
      "In an effort to keep your account secure, we've also limited certain features in your account. You'll be able to regain access to these features once you provide the information we've requested."
    ],
    button: {
      text: "Contact support",
      link: ""
    }
  },
  permanentlysuspended: {
    image: perSuspendedIcon,
    title: "Hold up!",
    details: [
      "Sorry, the account you are trying to access has been permanently suspended. This account will not be restored, due to security reasons, our Compliance Team is unable to provide you with any further information.",
      "We wish you all the best."
    ]
  },
  restrictedregion: {
    image: tempSuspendedUsIcon,
    title: "Sorry, we aren't able to verify your identity",
    details: [
      "We were unable to verify some information in your Benk account, so we have temporarily suspended your account. Until we verify your account information and reactivate your account, you may be unable to use some of our products."
    ],
    button: {
      text: "Contact support",
      link: ""
    }
  },
  suspiciousactivity: {
    image: useAnotherDeviceIcon,
    title: "You can't use Benk at the moment",
    details: [
      "If, however, Benk concludes that you reside in a prohibited region or are violating applicable sanctions in any way, your account will be closed and Benk will take any other actions required by law.",
      "Additional information regarding account logins from prohibited regions is available"
    ],
    link: [
      {},
      {
        text: "here.",
        link: config.LEGAL_PAGE_URL
      }
    ],
    button: {
      text: "Contact support",
      link: ""
    }
  }
};
