import React, { useEffect, useState } from "react";
import { validate } from "../../../utils/common";
import Button from "../../../common/components/form/Button/Button";
import CustomizeInput from "../../../common/components/form/CustomizeInput";
import CustomizeTag from "../../../common/components/CustomizeTag";
import googleLogo from "../../../assets/images/google-logo.svg";
// Removed facebookLogo since it is not being used
// import facebookLogo from "../../../assets/images/facebook-logo.svg";
import { EMAIL_DOMAINS } from "../../../constants/constants";

const SignUpForm = ({
  onSubmit,
  errorMessage,
  handleSocialLogin,
  initialEmail,
  preferences
}) => {
  const [email, setEmail] = useState("");
  const [error, setErrorMessage] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [socialLoginClick, setSocialLoginClick] = useState(false);

  useEffect(() => {
    setEmail(initialEmail || "");
  }, [initialEmail]);

  const onSignUp = () => {
    const errMsg = validate("email", email);
    // setErrorMessage and stop if there's an error
    if (errMsg) return setErrorMessage(errMsg);
    setErrorMessage("");
    onSubmit(email);
  };

  const onEmailChange = e => {
    const value = e.target.value.trim() || "";
    setSuggestions([]);
    if (value.substr(-1) === "@") setSuggestions(EMAIL_DOMAINS);

    setEmail(value);
    if (error && !validate("email", e.target.value)) {
      setErrorMessage("");
    }
  };

  const onValidate = ({ target: { value } }) => {
    if (!socialLoginClick && value !== "") {
      if (value.substr(-1) !== "@") {
        setErrorMessage(validate("email", value));
      }
    }
    if (value === "") {
      setErrorMessage("");
    }
  };

  const onTagClick = text => {
    const concatEmail = email + text.toLowerCase();
    setEmail(concatEmail);
    setSuggestions([]);
    if (error && !validate("email", concatEmail)) {
      setErrorMessage("");
    }
    const errMsg = validate("email", concatEmail);
    if (errMsg) return setErrorMessage(errMsg);
    setErrorMessage("");
  };

  return (
    <>
      <div className="mt-60">
        <span
          className="cursor-pointer"
          onMouseDown={() => setSocialLoginClick(true)}
          onClick={() => {
            setErrorMessage("");
            setSocialLoginClick(false);
            handleSocialLogin("google");
          }}
        >
          <img src={googleLogo} alt="google" width={38} height={38} />
        </span>
        {/* Commented out Facebook login as it's unused
        <span
          className="ml-10 cursor-pointer"
          onMouseDown={() => setSocialLoginClick(true)}
          onClick={() => {
            setErrorMessage("");
            setSocialLoginClick(false);
            handleSocialLogin("facebook");
          }}
        >
          <img src={facebookLogo} alt="facebook" width={38} height={38} />
        </span> */}
      </div>
      <div className="fs-15 text-light-gray mt-2">or continue with email</div>
      <div className="mt-35">
        <CustomizeInput
          error={error}
          autoFocus
          value={email || ""}
          label={"Your email address"}
          onChange={onEmailChange}
          onBlur={onValidate}
          toolTip={{
            title: "Email Address",
            content: (
              <>
                We will not spam you, or sell or rent your email address. The
                Benk family of companies may keep you informed with
                personalized emails about products and services. See our{" "}
                <a
                  rel="noreferrer"
                  className="text-blue link fs-14"
                  href={preferences.privacy_url || ""}
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                for more details or to opt-out at any time.
              </>
            )
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) onSignUp();
          }}
        />
        <div className="error-text mt-10">{errorMessage || ""}</div>
      </div>
      {!!suggestions.length && (
        <div className="mt-20 d-flex justify-content-between">
          {suggestions.map(suggestion => (
            <CustomizeTag
              key={suggestion}
              text={suggestion}
              onClick={onTagClick}
            />
          ))}
        </div>
      )}

      <div className="mt-30">
        <Button
          buttonStyle="w-100"
          text="Continue"
          onClick={onSignUp}
          disabled={!!validate("email", email)}
        />
      </div>
    </>
  );
};

export default SignUpForm;
