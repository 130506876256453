export const initialInvitationState = () => ({
  details: null,
  invitedEmails: null,
  fetchInviteConfigLoading: false,
  fetchInviteConfigFailure: false,
  fetchInviteConfigLoaded: false,
  sendInvitationLoading: false,
  sendInvitationFailure: false,
  sendInvitationLoaded: false
});
