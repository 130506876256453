import React from "react";
import { numberWithCommas } from "../../../utils/common";

const NumberValue = ({
  mainClass,
  subClass,
  value,
  currency = {},
  isDecimalNotDisplay
}) => {
  return (
    <span className={mainClass || ""}>
      {currency.symbol_first ? currency.symbol : null}
      {numberWithCommas(
        value.toString().split(".")[0] || 0,
        currency.thousands_separator
      )}
      {!isDecimalNotDisplay && (
        <span className={subClass || ""}>
          .
          {
            parseFloat(value || 0)
              .toFixed(Number(value) > -1 ? currency.precision || 2 : 2)
              .split(".")[1]
          }
        </span>
      )}
      {!currency.symbol_first ? ` ${currency.symbol || ""}` : null}
    </span>
  );
};

export default NumberValue;
