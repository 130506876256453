import React from "react";
import HeaderLogo from "../../../assets/images/BenkLogo.png";
import "./SignupHeader.scss";
import LinkButton from "../form/LinkButton";

const SignupHeader = React.memo(props => {
  const {
    title,
    subTitle,
    linkText,
    onLinkClick,
    hideSkip,
    headerClass
  } = props;

  return (
    <div className="signup-header">
      <div className="logo-container">
        <img className="header-logo" src={HeaderLogo} alt="Header Logo" />
        {linkText && !hideSkip && (
          <div className="header-link">
            <LinkButton text={linkText} onClick={onLinkClick} />
          </div>
        )}
      </div>
      {title && (
        <div className={`header-title text-center fs-22 ${headerClass || ""}`}>
          {title}
        </div>
      )}
      {subTitle && <div className="header-subtitle">{subTitle}</div>}
    </div>
  );
});

export default SignupHeader;
