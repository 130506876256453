import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomPopover from "../CustomPopover";
import copyIcon from "../../../assets/images/copy.svg";
import "./CopyToClipboard.scss";

const CopyText = ({
  displayValue,
  copyValue,
  mainClassName,
  className,
  btnClassName,
  copyImage,
  iconWidth
}) => {
  const [isVisible, handleVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        handleVisible(prevVisibility => !prevVisibility);
      }, 1500);
    }
  }, [isVisible]);

  const onCopy = () => {
    handleVisible(prevVisibility => !prevVisibility);
  };

  return (
    <span className={`input-mast ${mainClassName || ""}`}>
      <CustomPopover
        title="Copied to clipboard"
        trigger="click"
        visible={isVisible}
        onVisibleChange={() => {}}
        innerWidth={150}
      >
        <span
          className={`pointer-none ${className}`}
          id="copy_text"
          onClick={() => handleVisible(!isVisible)}
        >
          {displayValue}
        </span>
      </CustomPopover>
      <div className={btnClassName}>
        <CopyToClipboard onCopy={onCopy} text={copyValue.replace(/ /g, "")}>
          <span>
            <img
              src={copyImage || copyIcon}
              alt="copy"
              width={iconWidth || 17}
            />
          </span>
        </CopyToClipboard>
      </div>
    </span>
  );
};

export default CopyText;
