import React from "react";
import Countdown from "antd/lib/statistic/Countdown";

const CountdownTimer = props => (
  <Countdown
    title={null}
    value={props.deadline}
    onFinish={props.onFinish}
    //  format="HH:mm:ss:SSS"
    format={props.format}
  />
);

export default CountdownTimer;
