import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { fetchUserDetails } from "../redux/modules/userDetails/userDetailsActions";
import {
  authRoutes,
  freeRoutes,
  routePaths,
  signUpRoutes,
  unAuthRoutes
} from "../routes";
import { banAccounts } from "../constants/constants";
import { config } from "../utils/config";
import AppLayout from "../common/components/AppLayout";
import {
  clearStorage,
  getAuthToken,
  getFromStorage,
  parseUserFullName,
  redirectAccordingToUserInfo,
  setIntoStorage
} from "../utils/common";
import Loader from "../common/components/Loader";
// import InternetError from "./InternetError";

const LoggedInRoutes = user =>
  authRoutes.map((r, i) => (
    <Route
      key={i}
      path={r.path}
      exact={r.exact}
      render={props => <r.main {...props} user={user || {}} />}
    />
  ));

const LoggedOutRoutes = unAuthRoutes.map((r, i) => (
  <Route
    key={i}
    path={r.path}
    exact={r.exact}
    render={props => <r.main {...props} />}
  />
));

const Main = React.memo(props => {
  const {
    getUserDetails,
    profileFailed,
    profileLoading,
    location,
    userDetails,
    history
  } = props;
  const token = getAuthToken();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!unAuthRoutes.find(route => route.path === history.location.pathname)) {
      if (token) {
        fetchUserDetails();
      } else if (!banAccounts.includes(history.location.pathname)) {
        setInvitationCode();
        history.push(routePaths.signUp);
      }
    } else if (getFromStorage("isLoggedIn") && token) {
      fetchUserDetails();
    }
  }, []);

  const setInvitationCode = () => {
    if (
      location.pathname &&
      location.pathname.split("/")[1] === routePaths.inviteUrl.split("/")[1]
    ) {
      const invite_code = location.pathname.split("/")[2];
      if (invite_code) {
        setIntoStorage({ invite_code });
      }
    }
  };

  const fetchUserDetails = () => {
    setLoading(true);
    const onSuccess = () => {};
    const onFail = () => {};
    getUserDetails({ onSuccess, onFail });
  };

  useEffect(() => {
    if (profileFailed) {
      // clearStorage();
      // history.push(routePaths.signUp);
    }
   /* const interComEmail = localStorage.getItem("email"); 
    <!-- const interComName = localStorage.getItem("name"); -->
    <!-- const interComCreatedAt = localStorage.getItem("created_at"); -->
    <!-- if ( -->
     <!--  (userDetails && -->
     <!--  userDetails.email &&  -->
     <!--  (userDetails.first_name || userDetails.last_name) && -->
      <!--   userDetails.created_at) || -->
     <!--  (interComEmail && interComName && interComCreatedAt) -->
    <!-- ) { --> 
      let name = userDetails ? parseUserFullName(userDetails) : interComName;

      window.Intercom("boot", {
        app_id: config.INTERCOM_APP_ID,
        name: name,
        email: userDetails ? userDetails.email : interComEmail,
        created_at: userDetails ? userDetails.created_at : interComCreatedAt,
      });
    } else {
      window.Intercom("boot", {
        app_id: config.INTERCOM_APP_ID
      });
      //window.Intercom("show");
    } */
    if (userDetails && userDetails.email) {
      if (
        signUpRoutes.includes(history.location.pathname) ||
        freeRoutes.includes(history.location.pathname) ||
        location.pathname.split("/")[1] === routePaths.inviteUrl.split("/")[1]
      ) {
        const redirectTo = redirectAccordingToUserInfo(userDetails);
        if (redirectTo) {
          setLoading(false);
          history.push(redirectTo);
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (profileFailed) {
      // clearStorage();
      // setLoading(false);
      // history.push(routePaths.signUp);
    }
  }, [profileFailed, profileLoading]);

  if (profileLoading || isLoading) {
    return <Loader />;
  }

  return (
    <>
     {/* <InternetError />*/}
      <AppLayout>
        <Switch>
          {[
            token && Object.keys(userDetails || {}).length
              ? LoggedInRoutes(userDetails)
              : LoggedOutRoutes
          ]}
        </Switch>
      </AppLayout>
    </>
  );
});

const mapStateToProps = state => {
  return {
    userDetails:
      state.userDetails.details ||
      (state.signUpReducer.userDetails &&
        (state.signUpReducer.userDetails.user || {})),
    profileLoading: state.userDetails.fetchUserDetailsLoading,
    profileFailed: state.userDetails.fetchUserDetailsFailure
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: payload => dispatch(fetchUserDetails(payload))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
