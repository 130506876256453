import { handleActions } from "redux-actions";
import { initialCommonState } from "./initialCommonState";
import { CommonConstants } from "./commonConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const commonReducer = handleActions(
  {
    [requestSuccess(CommonConstants.FETCH_COUNTRIES)]: (
      state,
      action
    ) => ({
      ...state,
      countries: (action.payload && action.payload.items) || [],
      fetchCountriesLoading: false,
      fetchCountriesFailure: false,
      fetchCountriesLoaded: true
    }),
    [requestPending(CommonConstants.FETCH_COUNTRIES)]: state => ({
      ...state,
      fetchCountriesLoading: true,
      fetchCountriesLoaded: false,
      fetchCountriesFailure: false
    }),
    [requestFail(CommonConstants.FETCH_COUNTRIES)]: state => ({
      ...state,
      fetchCountriesLoading: false,
      fetchCountriesLoaded: true,
      fetchCountriesFailure: true
    })
  },
  initialCommonState()
);
