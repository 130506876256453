export const initialReportMerchant = () => ({
  typeOfIssues: {},

  fetchTypeOfIssuesLoading: false,
  fetchTypeOfIssuesFailure: false,
  fetchTypeOfIssuesLoaded: false,

  reportMerchantActivityLoading: false,
  reportMerchantActivityFailure: false,
  reportMerchantActivityLoaded: false
});
