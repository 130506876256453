import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SignupHeader from "../../common/components/SignupHeader";
import FlatOtpInput from "../../common/components/form/FlatOtpInput";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import { ACCESS_TOKEN_TYPE, OTP_INPUT_NUM } from "../../constants/constants";
import {
  displayErrorMessage,
  getFromStorage,
  redirectAccordingToUserInfo,
  setIntoStorage
} from "../../utils/common";
import { routePaths } from "../../routes";
import { mfaVerifyOTP } from "../../redux/modules/signUp/signUpActions";
import Loader from "../../common/components/Loader";

let timer = null;

const TwoFactorAuth = props => {
  const { history, mfaVerifyOTP, preferences } = props;
  const [otp, setOtp] = useState("");
  const [isNoChange, setSeconds] = React.useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [otp_uuid, setOtpUUID] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const email = getFromStorage("email");
    const otp_uuid = getFromStorage("otp_uuid");
    if (email && otp_uuid) {
      setOtpUUID(otp_uuid);
      setIntoStorage({
        otp_uuid: ""
      });
    } else {
      history.push(routePaths.signUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timer = setInterval(() => {
      setOtp(prevState => {
        if (!prevState) {
          setErrMsg(
            "Please enter the 6-digit code you see in the Authenticator app."
          );
        }
        setSeconds(prevState => prevState + 1);
        return prevState;
      });
    }, 5000);
  }, []);

  useEffect(() => {
    if (isNoChange) {
      clearInterval(timer);
    }
  }, [isNoChange]);

  const onOtpChange = value => {
    setErrMsg("");
    setOtp(value);
    if (value && value.toString().length === OTP_INPUT_NUM) {
      setLoading(true);
      const onSuccess = data => {
        if (data.user && data.user.email) {
          setIntoStorage({
            email: data.user.email,
            isLoggedIn: true,
            AuthToken: `${ACCESS_TOKEN_TYPE} ${data.Token || ""}`,
            otp_uuid: ""
          });
          if (data && data.user.status === "Active") {
            const redirectTo = redirectAccordingToUserInfo(data.user);
            history.push(redirectTo);
          }
        } else {
          history.push(routePaths.signUp);
        }
        setLoading(false);
      };
      const onFail = err => {
        setOtp(null);
        setErrMsg(displayErrorMessage(err));
        setLoading(false);
      };
      mfaVerifyOTP({
        data: { code: value, otp_uuid },
        onSuccess,
        onFail
      });
    }
  };

  return (
    <PageLayout divClass="col">
      {isLoading && <Loader />}
      <div>
        <SignupHeader title="2-Step verification" />
        <div className="mt-10 fs-15 text-center text-gray">
          Enter the 6-digit code you see in the app.
        </div>
      </div>
      <div>
        <FlatOtpInput
          onChange={onOtpChange}
          numInputs={3}
          value={otp || ""}
          maxLength={6}
        />
        <div className="error-text text-center mt-4" style={{ minHeight: 50 }}>
          {errMsg || ""}
        </div>
      </div>
      <div className="fs-12 mb-25">
        <div className="text-gray text-center">
          By proceeding, you agree to Benk's{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.terms_url || ""}
            target="_blank"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.privacy_url || ""}
            target="_blank"
          >
            Privacy Policy.
          </a>{" "}
          See the{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.restrictions_url || ""}
            target="_blank"
          >
            Restrictions.
          </a>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    user:
      state.signUpReducer.userDetails && state.signUpReducer.userDetails.user
        ? state.signUpReducer.userDetails.user
        : {},
    preferences: state.preferences.preferences || {}
  };
};
const mapDispatchToProps = dispatch => {
  return {
    mfaVerifyOTP: payload => dispatch(mfaVerifyOTP(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);
