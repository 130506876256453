import React, { useEffect, useState } from "react";
import ClaimDollarsModal from "./Components/ClaimDollarsModal";
import Button from "../../common/components/form/Button/Button";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import "./ClaimDollars.scss";
import { fetchInvitationConfig } from "../../redux/modules/invitation/invitationActions";
import { connect } from "react-redux";
import Loader from "../../common/components/Loader";
import NumberValue from "../Transactions/Components/NumberValue";
import { accountSuspend } from "../../redux/modules/claimBonus/claimBonusAction";
import { routePaths } from "../../routes";
import MergeAccountConfirmation from "./Components/MergeAccountConfirmation";
import LinkButton from "../../common/components/form/LinkButton";
import {redirectToLoginApp} from "../../utils/common";
// import ClaimResult from "./ClaimResult";

const ClaimDollars = props => {
  const [show, isShow] = useState(false);
  const [confirmation, toggleConfirmation] = useState(false);
  const {
    history,
    getInviteConfig,
    config,
    configLoading,
    accountSuspend,
    accountSuspendLoading
  } = props;

  useEffect(() => {
    if (!(config && config.get_amount)) {
      getInviteConfig();
    }
  }, []);
  const onMergeAccount = () => {
    toggleConfirmation(false);
    const onSuccess = () => {
      window.location.href = `${window.location.origin}${routePaths.accountStatus}?status=temporarilysuspended`;
    };
    const onFail = () => {};
    accountSuspend({ onSuccess, onFail });
  };

  const onCancelButton = () => {
    redirectToLoginApp();
  }

  return (
      <PageLayout divClass="container-pad claim-dollars">
        {(configLoading || accountSuspendLoading) && <Loader />}
        <ClaimDollarsModal
            history={history}
            onMergeAccount={() => {
              isShow(false);
              toggleConfirmation(true);
            }}
            visible={show}
            onClick={() => {
              isShow(false);
              history.push(`${routePaths.claimResult}?status=processing`);
            }}
            onClose={() => {
              isShow(false);
            }}
        />
        <MergeAccountConfirmation
            onMergeAccount={onMergeAccount}
            visible={confirmation}
            onClose={() => {
              isShow(true);
              toggleConfirmation(false);
            }}
        />
        {config && config.get_amount && (
            <>
              {" "}
              <div className="mt-80 text-center text-black">
                <div className="claim-madel">
                  <div className="claim-amount">
                    <NumberValue
                        currency={config.get_currency || {}}
                        value={config.get_amount || 0}
                        isDecimalNotDisplay
                    />
                  </div>
                </div>
                <div className="mt-35 font-wotfard fw-600 fs-34 claim-reward">
                  There&apos;s{" "}
                  <NumberValue
                      currency={config.get_currency || {}}
                      value={config.get_amount || 0}
                      isDecimalNotDisplay
                  />{" "}
                  <br />
                  waiting for you
                </div>
                <div className="mt-25 fs-18 mb-20">
                  <div className="fw-bold">The best part?</div>
                  The money you get will be chosen at random. You could get money
                  like USD, EUR, Gold, Bitcoin, and more.
                </div>
              </div>
              <div className="mb-10">
                <Button
                    text={
                      <span>
                  Claim your{" "}
                        <NumberValue
                            currency={config.get_currency || {}}
                            value={config.get_amount || 0}
                            isDecimalNotDisplay
                        />{" "}
                        now
                </span>
                    }
                    onClick={() => isShow(true)}
                />
              </div>
              <div className="mb-30 text-center">
                <LinkButton
                    text={"Cancel"}
                    onClick={onCancelButton}
                />
              </div>
            </>
        )}
        {/*{success && <ClaimResult history={history} />}*/}
      </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    configLoading: state.invitation.fetchInviteConfigLoading,
    accountSuspendLoading: state.claimBonus.accountSuspendLoading,
    config: state.invitation.details || {}
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getInviteConfig: payload => dispatch(fetchInvitationConfig(payload)),
    accountSuspend: payload => dispatch(accountSuspend(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDollars);
