import React from "react";
import { Popover } from "antd";
import "./CustomPopover.scss";

const CustomPopover = ({
  className,
  children,
  title,
  trigger,
  innerWidth,
  visible,
  onVisibleChange,
  color
}) => {
  const props = {};
  if (onVisibleChange) {
    props.visible = visible;
    props.onVisibleChange = onVisibleChange;
  }
  return (
    <Popover
      {...props}
      title={title}
      className={`custom-popover ${className || ""}`}
      trigger={trigger || "click"}
      color={color || "#fff"}
      overlayInnerStyle={innerWidth ? { width: innerWidth } : {}}
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
