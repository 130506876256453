import { createAction } from "redux-actions";

import { PersonalInfoConstants } from "./personalInfoConstants";

export const fetchCitizenShip = createAction(
  PersonalInfoConstants.FETCH_CITIZEN_SHIP
);

export const postPersonalInfo = createAction(
  PersonalInfoConstants.POST_PERSONAL_INFO
);

export const uploadProfileImage = createAction(
  PersonalInfoConstants.UPLOAD_USER_IMAGE
);
