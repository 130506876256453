import React from "react";
import { connect } from "react-redux";
import SignupHeader from "../../common/components/SignupHeader";
import ShieldIcon from "../../assets/images/right-shield-dark-lg.svg";
import Button from "../../common/components/form/Button/Button";
import { redirectToLoginApp } from "../../utils/common";
import "./Skip02.scss";
import { storeAgreeAndContinue } from "../../redux/modules/signUp/signUpActions";
import Loader from "../../common/components/Loader";
import StickyFooter from "../../common/components/StickyFooter";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import {
  setIntoStorage
} from "../../utils/common";

const Skip02 = React.memo(props => {
  const {
    history,
    agreeTerms,
    storeAgreeAndContinue,
    user
  } = props;
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (user && user.agreeterms) {
      redirectToLoginApp(null, user, history);
    }
  }, [user]);

  const handleBtnAgreeTerm = React.useCallback(() => {
    setLoading(true);
    setIntoStorage({isNewAccount : true});

    const onSuccess = () => {
      setLoading(false);
      redirectToLoginApp(null, user, history);
    };
    const onFail = () => {
      setLoading(false);
      setError("Something went wrong.");
    };
    storeAgreeAndContinue({ onSuccess, onFail });
  }, [storeAgreeAndContinue, setError, history]);

  return (
    <div className="skip02-container container-pad">
      {loading && <Loader />}
      <div className="skip-02-header-container">
        <SignupHeader />
        <div className="header-sub-container">
          <span>
            <img src={ShieldIcon} alt="Shield Icon" />
          </span>
          <div className="sub-container-title">Benk protects its users</div>
          <div className="sub-container-sub-title">
            Benk committed to compliance with all applicable laws and
            regulations regarding Anti-Money Laundering ({'"AML"'}).
          </div>
        </div>
      </div>
      <div className="skip2-footer">
        {error && <div className="error-text">{error}</div>}
        <div className="signup-footer-top-text">
          When you want to transact past certain monetary thresholds, we are
          required to conduct Customer Due Diligence (“CDD”) on a risk-based
          approach.
        </div>
        <div className="skip2-footer-btn">
          <Button text="AGREE AND CONTINUE" onClick={handleBtnAgreeTerm} />
        </div>
      </div>
      <StickyFooter
        stickyText="Welcome to the demo version of International Banking project powered by Opermont. All data, currencies and amounts are for demo and test only."
      />
    </div>
  );
});

const mapStateToProps = state => {
  return {
    agreeTerms: state.signUpReducer.agreeTerms
  };
};
const mapDispatchToProps = dispatch => {
  return {
    storeAgreeAndContinue: payload => dispatch(storeAgreeAndContinue(payload)),
    getUserDetails: () => dispatch(fetchUserDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Skip02);
