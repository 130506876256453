import { handleActions } from "redux-actions";
import { initialClaimBonusState } from "./initialClaimBonusState";
import { ClaimBonusConstants } from "./claimBonusConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const claimBonusReducer = handleActions(
  {
    [requestSuccess(ClaimBonusConstants.FETCH_CLAIM_BONUS)]: state => ({
      ...state,
      fetchClaimBonusLoading: false,
      fetchClaimBonusFailure: false,
      fetchClaimBonusLoaded: true
    }),
    [requestPending(ClaimBonusConstants.FETCH_CLAIM_BONUS)]: state => ({
      ...state,
      fetchClaimBonusLoading: true,
      fetchClaimBonusFailure: false,
      fetchClaimBonusLoaded: false
    }),
    [requestFail(ClaimBonusConstants.FETCH_CLAIM_BONUS)]: state => ({
      ...state,
      fetchClaimBonusLoading: false,
      fetchClaimBonusFailure: true,
      fetchClaimBonusLoaded: false
    }),
    [requestSuccess(ClaimBonusConstants.ACCOUNT_SUSPEND)]: state => ({
      ...state,
      accountSuspendLoading: false,
      accountSuspendFailure: false,
      accountSuspendLoaded: true
    }),
    [requestPending(ClaimBonusConstants.ACCOUNT_SUSPEND)]: state => ({
      ...state,
      accountSuspendLoading: true,
      accountSuspendFailure: false,
      accountSuspendLoaded: false
    }),
    [requestFail(ClaimBonusConstants.ACCOUNT_SUSPEND)]: state => ({
      ...state,
      accountSuspendLoading: false,
      accountSuspendFailure: false,
      accountSuspendLoaded: true
    })
  },
  initialClaimBonusState()
);
