import React from "react";
import Button from "react-bootstrap/Button";
import "./StickyFooter.scss";

const StickyFooter = React.memo(props => {
	const {stickyText, btnText, onClick, showBtn, position} = props;

	return (
		<div className={`sticky-block${position? '-up': ''} col-md-5 col-lg-5 p-0`}>
			<div className="content">
				<div className="sticky-text">
					{stickyText}
				</div>
				{showBtn && 
					<div className="btn-sticky-container">
						<Button
							variant="dark"
							onClick={onClick}
							className="btn-sticky-text"
						>
							{btnText}
						</Button>
					</div>
				}
			</div>
		</div>
	);
});

export default StickyFooter;