import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../common/components/form/Button";
import LinkButton from "../../common/components/form/LinkButton";
import FullScreenModal from "../../common/components/FullScreenModal";
import "./LaunchScreen.scss";

import { getPreferences } from "../../redux/modules/preferences/preferencesActions";

import launchScreenBG from "../../assets/images/launch-screen.svg";
import infoIcon from "../../assets/images/info.svg";
import logo from "../../assets/images/BenkLogo.png";
import rightArrowIcon from "../../assets/images/right-arrow-long.svg";
import closeIcon from "../../assets/images/icon-close.svg";
import locationIcon from "../../assets/images/icon-location.svg";
import iconEmail from "../../assets/images/icon-email.svg";
import { routePaths } from "../../routes";
import Div100vh from "react-div-100vh";
import MetaHeader from "../../common/components/MetaHeader";
import StickyFooter from "../../common/components/StickyFooter";

const LaunchScreen = React.memo(props => {
  const { history, contactList, contactEmail, getPreferences } = props;
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isInfoButtonClicked, setIsInfoButtonClicked] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const onSuccess = () => {};
    const onFail = () => {};
    getPreferences({ onSuccess, onFail });

    const interval = setInterval(() => {
      setSeconds(prev => prev + 1);
    }, 1000);
    if (isInfoButtonClicked) {
      clearInterval(interval);
      setSeconds(1000);
    }
    return () => clearInterval(interval);
  }, [setSeconds, isInfoButtonClicked, getPreferences]);

  useEffect(() => {
    if (seconds === 15 && !isInfoButtonClicked) {
      handleLoginBtnClick("signup");
    }
  }, [history, isInfoButtonClicked, seconds]);

  const handleLoginBtnClick = React.useCallback(
    arg => {
      history.push(`${routePaths.signUp}?${arg}`);
    },
    [history]
  );

  const handleInfoModalToggle = React.useCallback(() => {
    setIsInfoButtonClicked(true);
    setShowInfoModal(prev => !prev);
  }, [setShowInfoModal, setIsInfoButtonClicked]);

  return (
    <Div100vh className="launch-screen-container mt-70">
      <MetaHeader
        // title="Get more from your money | TORG"
        // description="Enter your email to look up your account or create a new one."
        // keywords="create TORG account, TORG app, bank with TORG, TORG login"
        // image="/images/home.png"
      />
      <StickyFooter
          stickyText="Welcome to the demo version of International Banking project powered by Opermont. All data, currencies and amounts are for demo and test only. You will be dealing with demo or fake money in the entire system."
          position="up"
        />
      <div className="content">
        {/*<div className="info-icon-container">
          <span onClick={handleInfoModalToggle}>
            <img src={infoIcon} width={28} height={28} alt="info-icon" />
          </span>
        </div>*/}
        <div className="bottom-content">
          <img src={launchScreenBG} width="120" alt="LaunchScreen" />
          <div className="logo-container">
            <img src={logo} style={{width:"100px"}} alt="neo-benk" />
            <div className="secondaryText sub-title">
              Get more from your money
            </div>
          </div>
          <div className="container-pad">
            <Button
              text="Get started - it’s free"
              appendIcon={<img src={rightArrowIcon} alt="neo-benk" />}
              textLeftAlign={true}
              onClick={() => handleLoginBtnClick("signup")}
              buttonStyle="custom-button"
            />
          </div>
          <div className="">
            <p className="bottom-text">
              Already have an account?{" "}
              <LinkButton
                text="Log in"
                onClick={() => handleLoginBtnClick("login")}
                styleClass="link-btn"
              />
            </p>
          </div>
        </div>
      </div>
      <FullScreenModal
        visible={showInfoModal}
        children={
          <div className="launch-screen-modal">
            <span className="btn-close back-button" onClick={handleInfoModalToggle}>
              <img src={closeIcon} width={14} height={14} alt="close-icon" />
            </span>
            <div className="mt-50 modal-data">
              {contactList &&
                contactList.length &&
                contactList.map((item, index) => (
                  <div className="address-list" key={index}>
                    <img
                      src={locationIcon}
                      width={16}
                      height={20}
                      alt="location-icon"
                      className="location-icon"
                    />
                    <div className="address-text-container">
                      <p className="primaryText">{item.title}</p>
                      <div className="address-text-sub-container">
                        <span className="secondaryText">{item.address}</span>
                        <span className="secondaryText">{item.phone}</span>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="email-address-list">
                <img
                  src={iconEmail}
                  width={20}
                  height={14}
                  alt="email-icon"
                  className="email"
                />
                <a
                  className="email-address-text"
                  href={`mailto:${contactEmail}`}
                >
                  {contactEmail}
                </a>
              </div>
            </div>
            <p className="modal-bottom-text">
              Benk Swiss activities do not fall under the framework of
              regulated financial services, and we are not supervised by the
              FINMA (Swiss Financial Market Supervisory Authority).
            </p>
          </div>
        }
      />
    </Div100vh>
  );
});

const mapStateToProps = state => {
  return {
    contactList: state.preferences.preferences.contactList,
    contactEmail: state.preferences.preferences.complianceEmail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPreferences: payload => dispatch(getPreferences(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchScreen);
