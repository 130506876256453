import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { UserDetailsConstants } from "./userDetailsConstants";

function* getUserDetails(action) {
  const userInfoPayload = {
    type: UserDetailsConstants.FETCH_USER_DETAILS,
    method: "GET",
    url: `user/details`,
    success: action.payload ? action.payload.onSuccess : null,
    fail: action.payload ? action.payload.onFail : null
  };
  if (action.payload && action.payload.data) {
    userInfoPayload.method = "POST";
    userInfoPayload.data = action.payload.data;
  }
  yield call(request(userInfoPayload), action);
}

function* setSelectedAccount(action) {
  yield call(
    request({
      type: UserDetailsConstants.SET_SELECTED_CURRENCY,
      method: "POST",
      url: `panel/setlocalcurrency`,
      data: action.payload
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(UserDetailsConstants.FETCH_USER_DETAILS, getUserDetails);
  yield takeLatest(
    UserDetailsConstants.SET_SELECTED_CURRENCY,
    setSelectedAccount
  );
}
