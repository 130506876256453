import { call, takeLatest, takeEvery } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { identityVerificationConstants } from "./identityVerificationConstants";

function* getUserInfo(action) {
  const userInfoPayload = {
    type: identityVerificationConstants.GET_USER_INFO,
    method: "GET",
    url: `user/details`,
    success: action.payload ? action.payload.onSuccess : null,
    fail: action.payload ? action.payload.onFail : null
  };
  yield call(request(userInfoPayload), action);
}

function* getVeriffUrl(action) {
  yield call(
    request({
      type: identityVerificationConstants.GET_VERIFF_URL,
      method: "GET",
      url: `user/veriff`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* setSkipIdentity(action) {
  yield call(
    request({
      type: identityVerificationConstants.SET_SKIP_IDENTITY,
      method: "POST",
      url: `panel/skipidentify`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(identityVerificationConstants.GET_USER_INFO, getUserInfo);
  yield takeLatest(identityVerificationConstants.GET_VERIFF_URL, getVeriffUrl);
  yield takeEvery(identityVerificationConstants.SET_SKIP_IDENTITY, setSkipIdentity);
}
