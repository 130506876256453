import React from "react";
import loading from "../../../../assets/images/loading-yl.gif";
import "./Processing.scss";

const Processing = () => (
  <div className="processing">
    <div className="text-center">
      <img src={loading} alt="loading" width={55} />
    </div>
    <div className="mt-30 font-wotfard fw-600 fs-28 text-center text-black">
      Processing
    </div>
    <div className="mt-12 fw-500 fs-18 text-center text-gray">
      This may take a few moments, but will ultimately be worth it.
    </div>
  </div>
);

export default Processing;
