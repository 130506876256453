import React, { useEffect, useState } from "react";
import { ACCOUNT_INPUT_NUM } from "../../constants/constants";
import CustomizeOtpInput from "../../common/components/form/CustomizeOtpInput";
import {
  displayErrorMessage,
  getFromStorage,
  setIntoStorage
} from "../../utils/common";
import "./AccountNumber.scss";

const AccountInput = props => {
  const { onConfirm, onContinue, checkIfAccountAvailable } = props;
  const [account_number, setAccNumber] = useState("");
  const [msgStatus, setMsgStatus] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let accountNo = getFromStorage("accountNumber");
    if (accountNo) {
      accountNo = accountNo.substr(0, accountNo.length - 2);
      setAccNumber(accountNo);
      manageDisplayMsg("success", null, true);
    }
  }, []);

  const onAccChange = (value, key) => {
    if (value && value.length && Number(value.split("")[0]) === 0) {
      value = "";
      setAccNumber(value);
      if (key === "Backspace" || key === "Delete") return;
      manageDisplayMsg(
        "error",
        'Sorry, your account number can\'t start with the number "0"',
        false
      );
      return;
    }
    setMsgStatus("");
    setMessage("");
    setAccNumber(value);
    onConfirm(false);
    if (value.length === 8) {
      onCheckAccIsAvailable(value);
    }
  };

  const manageDisplayMsg = (status, msg, flag) => {
    setMsgStatus(status);
    setMessage(msg || "It's available!");
    onConfirm(flag);
  };

  const onCheckAccIsAvailable = accNumber => {
    const onSuccess = data => {
      setIntoStorage({ accountNumber: data.account_number || "" });
      manageDisplayMsg("success", null, true);
    };
    const onFail = err => {
      manageDisplayMsg("error", displayErrorMessage(err), false);
    };
    checkIfAccountAvailable({
      data: {
        account_number: accNumber
      },
      onSuccess,
      onFail
    });
  };

  const onSubmit = () => {
    onContinue();
  };

  return (
    <>
      <CustomizeOtpInput
        className={`account-number-input ${msgStatus}`}
        onChange={onAccChange}
        numInputs={ACCOUNT_INPUT_NUM}
        onKeyDown={onSubmit}
        value={account_number}
        firstDigitDoesNotToBeZero
      />
      <p
        className={`${
          msgStatus === "error" ? "text-red" : "text-blue"
        } fs-14 text-center mt-10 mb-0`}
      >
        {message}
      </p>
    </>
  );
};

export default AccountInput;
