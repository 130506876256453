import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { ClaimBonusConstants } from "./claimBonusConstants";

function* getClaimBonus(action) {
  yield call(
    request({
      type: ClaimBonusConstants.FETCH_CLAIM_BONUS,
      method: "GET",
      url: `invitation/claim`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}
function* accountSuspend(action) {
  yield call(
    request({
      type: ClaimBonusConstants.ACCOUNT_SUSPEND,
      method: "POST",
      url: `/invitation/temporarily_suspended`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(ClaimBonusConstants.FETCH_CLAIM_BONUS, getClaimBonus);
  yield takeLatest(ClaimBonusConstants.ACCOUNT_SUSPEND, accountSuspend);
}
