import { config } from "../utils/config";
import LaunchScreen from "../pages/LaunchScreen";
import IdentityVerification from "../pages/IdentityVerification";
import Skip02 from "../pages/Skip02";
import Skip01 from "../pages/Skip01/Skip01";
import PersonalInfo from "../pages/PersonalInfo";
import AccountNumber from "../pages/AccountNumber";
import ConfirmAccountNumber from "../pages/ConfirmAccountNumber";
import SignUp from "../pages/SignUp";
import SocialAccVerify from "../pages/SocialAccVerify";
import ConfirmEmail from "../pages/ConfirmEmail";
import AccountStatus from "../pages/AccountStatus";
import TwoFactorAuth from "../pages/TwoFactorAuth";
import Logout from "../pages/Logout";
import ClaimResult from "../pages/ClaimDollars/ClaimResult";
import ClaimDollars from "../pages/ClaimDollars";
import NotFound from "../pages/NotFound";
import WaitList from "../pages/Waitlist";

export const routePaths = {
  launchScreen: "/",
  signUp: "/get_started",
  twoFA: "/get_started/2SV",
  confirmEmail: "/get_started/otp",
  socialAccVerify: "/social-acc-verify",
  identityVerification: "/new/identity_verification",
  skip02: "/new/CDD",
  skip01: "/new/confirm",
  personalInfo: "/new/personal_info",
  accountNumber: "/new/NBAN",
  confirmAccountNumber: "/new/confirm_NBAN",
  accountStatus: "/user",
  waitList: "/wait_list",
  claimResult: "/claim_free_dollar/success",
  claimDollars: "/claim_free_dollar",
  inviteUrl: "/R",
  logout: "/logout"
};

export const dashboardRoute = `${config.APP_DOMAIN}/banking/accounts`;
export const authRedirect = `${config.APP_DOMAIN}/auth`;
export const dashboard = "/banking/accounts";
export const invite = "/send_free_dollars?invite=1";

export const signUpRoutes = [
  routePaths.confirmEmail,
  routePaths.personalInfo,
  routePaths.accountNumber,
  routePaths.confirmAccountNumber,
  routePaths.identityVerification,
  routePaths.skip02,
  routePaths.skip01,
  routePaths.claimDollars,
  routePaths.waitList,
  routePaths.claimResult,
];

export const freeRoutes = [
  routePaths.launchScreen,
  routePaths.signUp,
  routePaths.socialAccVerify,
  routePaths.confirmEmail,
  routePaths.accountStatus,
  routePaths.logout
];

export const authRoutes = [
  {
    title: "Personal Information",
    path: routePaths.personalInfo,
    url: routePaths.personalInfo,
    exact: true,
    main: PersonalInfo
  },
  {
    title: "Account Number",
    path: routePaths.accountNumber,
    url: routePaths.accountNumber,
    exact: true,
    main: AccountNumber
  },
  {
    title: "Confirm Account Number",
    path: routePaths.confirmAccountNumber,
    url: routePaths.confirmAccountNumber,
    exact: true,
    main: ConfirmAccountNumber
  },
  {
    title: "Identity Verification",
    path: routePaths.identityVerification,
    url: routePaths.identityVerification,
    exact: true,
    main: IdentityVerification
  },
  {
    title: "Skip 02",
    path: routePaths.skip02,
    url: routePaths.skip02,
    exact: true,
    main: Skip02
  },
  {
    title: "Skip 01",
    path: routePaths.skip01,
    url: routePaths.skip01,
    exact: true,
    main: Skip01
  },
  {
    title: "Claim Result",
    path: routePaths.claimResult,
    url: routePaths.claimResult,
    exact: true,
    main: ClaimResult
  },
  {
    title: "Claim Dollars",
    path: routePaths.claimDollars,
    url: routePaths.claimDollars,
    exact: true,
    main: ClaimDollars
  },
  {
    title: "Wait List",
    path: routePaths.waitList,
    url: routePaths.waitList,
    exact: true,
    main: WaitList
  },
  {
    title: "Not Found",
    path: "*",
    url: "*",
    exact: true,
    main: NotFound
  }
];

export const unAuthRoutes = [
  {
    title: "Launch Screen",
    path: routePaths.launchScreen,
    url: routePaths.launchScreen,
    exact: true,
    main: LaunchScreen
  },
  {
    title: "Email Address",
    path: routePaths.signUp,
    url: routePaths.signUp,
    exact: true,
    main: SignUp
  },
  {
    title: "Email Address",
    path: routePaths.confirmEmail,
    url: routePaths.confirmEmail,
    exact: true,
    main: ConfirmEmail
  },
  {
    title: "Two Factor Authentication",
    path: routePaths.twoFA,
    url: routePaths.twoFA,
    exact: true,
    main: TwoFactorAuth
  },
  {
    title: "Social Account Verify",
    path: routePaths.socialAccVerify,
    url: routePaths.socialAccVerify,
    exact: true,
    main: SocialAccVerify
  },
  {
    title: "Account Status",
    path: routePaths.accountStatus,
    url: routePaths.accountStatus,
    exact: true,
    main: AccountStatus
  },
  {
    title: "Logout",
    path: routePaths.logout,
    url: routePaths.logout,
    exact: true,
    main: Logout
  },
  {
    title: "Not Found",
    path: "*",
    url: "*",
    exact: true,
    main: NotFound
  }
];
