import React from "react";
import "./VeriffSuccessModal.scss";
import CustomizeModal from "../../../../common/components/CustomizeModal";
import SuccessIcon from "../../../../assets/images/icon-done.svg";
import VerifiedIcon from "../../../../assets/images/icon-verified.svg";

const VeriffSuccessModal = React.memo(props => {
  const { visible, userName } = props;

  return (
    <CustomizeModal
      visible={visible}
      onClose={() => {}}
    >
      <div className="veriff-success-modal-container">
        <img src={SuccessIcon} width={65} height={65} />
        <p className="primary-text">{ userName } <span className="budge-icon"><img src={VerifiedIcon} width={20} height={20}/></span></p>
        <p className="secondary-text">
          Success! Your identity has been verified.
        </p>
      </div>
    </CustomizeModal>
  );
});

export default VeriffSuccessModal;