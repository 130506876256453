import { call, takeLatest, put, takeEvery } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { SignUpConstants } from "./signUpConstants";

function* getSocialRedirectLink(action) {
  yield call(
    request({
      type: SignUpConstants.SOCIAL_SIGNUP_REQUEST,
      method: "GET",
      url: `auth/config`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* signUpRequest(action) {
  yield call(
    request({
      type: SignUpConstants.SIGNUP_REQUEST,
      method: "POST",
      data: action.payload.data,
      url: `auth/email`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* generateEmail(action) {
  yield call(
    request({
      type: SignUpConstants.GENERATE_EMAIL,
      method: "GET",
      url: `auth/generate-email`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* verifyEmailOTP(action) {
  yield call(
    request({
      type: SignUpConstants.VERIFY_EMAIL_OTP,
      method: "POST",
      data: action.payload.data,
      url: `auth/checkotp`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* verifyMfaOTP(action) {
  yield call(
    request({
      type: SignUpConstants.MFA_OTP_VERIFY,
      method: "POST",
      data: action.payload.data,
      url: `auth/checkmfa`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* resendOTPRequest(action) {
  yield call(
    request({
      type: SignUpConstants.RESEND_OTP_REQUEST,
      method: "POST",
      url: `auth/resendotp`,
      data: action.payload.data,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function putSignUpUserDetails(payload) {
  put({
    type: "UPDATE_SIGNUP_USER",
    payload
  });
}

function* fetchSignUpProfile(action) {
  yield call(
    request({
      type: SignUpConstants.FETCH_SIGNUP_PROFILE,
      method: "GET",
      url: `auth/user/profile`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* storeAgreeAndContinue(action) {
  yield call(
    request({
      type: SignUpConstants.STORE_AGREE_AND_CONTINUE,
      method: "POST",
      url: `panel/agreeterms`,
      data: action.payload.data || null,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* cancelSignup(action) {
  yield call(
    request({
      type: SignUpConstants.CANCEL_SIGNUP,
      method: "GET",
      url: `user/cancelsignup`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(
    SignUpConstants.SOCIAL_SIGNUP_REQUEST,
    getSocialRedirectLink
  );
  yield takeLatest(SignUpConstants.SIGNUP_REQUEST, signUpRequest);
  yield takeLatest(SignUpConstants.GENERATE_EMAIL, generateEmail);
  yield takeLatest(SignUpConstants.VERIFY_EMAIL_OTP, verifyEmailOTP);
  yield takeLatest(SignUpConstants.MFA_OTP_VERIFY, verifyMfaOTP);
  yield takeLatest(SignUpConstants.RESEND_OTP_REQUEST, resendOTPRequest);
  yield takeEvery(SignUpConstants.UPDATE_SIGNUP_USER, putSignUpUserDetails);
  yield takeEvery(SignUpConstants.FETCH_SIGNUP_PROFILE, fetchSignUpProfile);
  yield takeEvery(
    SignUpConstants.STORE_AGREE_AND_CONTINUE,
    storeAgreeAndContinue
  );
  yield takeEvery(SignUpConstants.CANCEL_SIGNUP, cancelSignup);
}
