import React from "react";
import { FormLabel } from "react-bootstrap";
import moment from "moment";
import CustomizeSelectInput from "../CustomizeSelectInput/CustomizeSelectInput";
import { DATE_FORMAT, MONTHS } from "../../../../constants/constants";
import CustomizeInput from "../CustomizeInput";
import CustomizedSearchInput from "../CustomizedSearchInput/CustomizedSearchInput";
import "./CustomizeDob.scss";

const CustomizeDOB = React.memo(props => {
  const { label, onChange, error, defaultDate, setFocus, focusNext } = props;
  const dayRef = React.createRef();
  const yearRef = React.createRef();
  const monthRef = React.createRef();
  const [loaded, setLoaded] = React.useState(false);
  const [date, setDate] = React.useState({
    day: "",
    month: "",
    year: ""
  });

  React.useEffect(() => {
    if (setFocus) {
      if (monthRef.current && monthRef.current.focus)
        monthRef.current.focus();
    }
  }, [setFocus]);

  React.useEffect(() => {
    if (defaultDate) {
      let selectedDate = moment(defaultDate, DATE_FORMAT);
      if (selectedDate.isValid()) {
        let month = selectedDate.format("MM");
        let day = selectedDate.format("DD");
        let year = selectedDate.format("YYYY");
        setDate({
          day,
          month,
          year
        });
      }
      setLoaded(true);
    }
  }, [defaultDate, setDate, loaded, setLoaded]);

  const focusField = React.useCallback(
    key => {
      switch (key) {
        case "month":
          if (monthRef.current && monthRef.current.focus)
            monthRef.current.focus();
          break;
        case "day":
          if (dayRef.current && dayRef.current.focus) {
            dayRef.current.focus();
          }
          break;
        case "year":
          if (yearRef.current && yearRef.current.focus)
            yearRef.current.focus();
          break;
      }
    },
    [monthRef, dayRef, yearRef]
  );

  const handleInputChange = React.useCallback(
    (key, value) => {
      if (key === "day" || key === "year") {
        value = value.replace(/[^0-9]/g, "");
      }

      if (key === "day") {
        if (parseInt(value) > 31) {
          return;
        }
      }

      switch (key) {
        case "day":
          setDate(prev => ({ ...prev, day: value }));
          break;
        case "month":
          setDate(prev => ({ ...prev, month: value }));
          break;
        default:
          setDate(prev => ({ ...prev, year: value }));
          break;
      }
      onChange({ ...date, [key]: value });

      if (key === "month" && value !== "") {
        focusField("day");
      }
      if (key === "day" && value.length > 1) {
        focusField("year");
      }
    },
    [setDate, date, onChange]
  );

  const handleKeyPress = React.useCallback(
    (e, key) => {
      if (e.which === 13 && focusNext !== undefined) {
        if (key === "day") {
          if (yearRef.current && yearRef.current.focus)
            yearRef.current.focus();
        } else if (key === "year") {
          focusNext(prev => prev + 1);
        }
      }
    },
    [yearRef, focusNext]
  );

  const handleFieldOnBlur = React.useCallback(
    key => {
      if (key === "month") {
        dayRef.current.focus();
      }
    },
    [monthRef, dayRef, yearRef]
  );

  return (
    <div className="dob-container">
      <FormLabel className="dob-input-label">{label}</FormLabel>
      <div className="dob-input-container">
        <div className="month-input-container">
          <CustomizedSearchInput
            inputRef={monthRef}
            label="Month"
            value={MONTHS.filter(v => v.value === date.month)[0] || null}
            items={MONTHS}
            renderItem={option => option.label}
            onChange={(event, option) => {
              option && handleInputChange("month", option.value);
              handleFieldOnBlur("month");
            }}
            error={error ? error : ""}
            filterKeys={["label"]}
            hideErrors={true}
          />
          {/*<CustomizeSelectInput*/}
          {/*  onChange={e => handleInputChange("month", e.target.value)}*/}
          {/*  label="Month"*/}
          {/*  items={MONTHS}*/}
          {/*  value={date.month}*/}
          {/*  renderItem={option => option.label}*/}
          {/*  error={error}*/}
          {/*/>*/}
        </div>
        <div className="day-input-container">
          <CustomizeInput
            inputRef={dayRef}
            value={date.day}
            inputType="tel"
            label="Day"
            maxLength={2}
            name="dobDay"
            onChange={e => {
              handleInputChange("day", e.target.value);
            }}
            error={error}
            hideError={true}
            onKeyDown={e => {handleKeyPress(e, "day")}}
          />
        </div>
        <div className="year-input-container">
          <CustomizeInput
            inputRef={yearRef}
            value={date.year}
            inputType="tel"
            label="Year"
            maxLength={4}
            name="dobYear"
            onChange={e => {
              handleInputChange("year", e.target.value);
            }}
            error={error}
            hideError={true}
            onBlur={() => handleFieldOnBlur("year")}
            onKeyDown={e => {handleKeyPress(e, "year")}}
          />
        </div>
      </div>
      {error && <p className="form-error">{error}</p>}
    </div>
  );
});

export default CustomizeDOB;
