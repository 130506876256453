export const initialPersonalInfoState = () => ({
  fetchCitizenShipLoading: false,
  fetchCitizenShipFailure: false,
  fetchCitizenShipLoaded: false,
  citizenship: [],
  postPersonalInfoLoading: false,
  postPersonalInfoFailure: false,
  postPersonalInfoLoaded: false,
  uploadImageLoading: false,
  uploadImageFailure: false,
  uploadImageLoaded: false,
  personalInfoSubmitted: false
});
