import React from "react";
import CountdownTimer from "../CountdownTimer";
import { reSendCodeTime } from "../../../utils/common";

export function ResendCodeMessage(props) {
  const {
    isTimerOn,
    timeStartFrom,
    noOfAttempt,
    setTimerToggle,
    mainText,
    hideClass
  } = props;

  return (
    <>
      {isTimerOn ? (
        <div className="mb-20 text-center text-black">
          Please wait{" "}
          <CountdownTimer
            deadline={reSendCodeTime(timeStartFrom, noOfAttempt)}
            onFinish={() => setTimerToggle(!isTimerOn)}
            format="ss"
          />{" "}
          seconds before trying to resend OTP.
        </div>
      ) : (
        mainText
      )}
    </>
  );
}

export default ResendCodeMessage;
