import React from "react";
import "./CustomizeTag.scss";
const CustomizeTag = ({ text, onClick }) => {
  return (
    <span
      className="customize-tag cursor-pointer"
      onClick={() => onClick(text)}
    >
      {text}
    </span>
  );
};

export default CustomizeTag;
