import React from "react";
import { Modal } from "react-bootstrap";

import AppLayout from "../../components/AppLayout";
import "./FullScreenModal.scss";

const FullScreenModal = React.memo(props => {
  const { children, visible, onClose, style } = props;

  return (
    <Modal
      className="full-screen-modal"
      show={visible}
      onHide={onClose}
      centered
      animation={false}
      style={style ? style : {}}
    >
      <AppLayout>
        {children}
      </AppLayout>
    </Modal>
  );
});

export default FullScreenModal;
