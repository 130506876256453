import { handleActions } from "redux-actions";
import { AccountNumberConstatns } from "./accountNumberConstants";

import {
  requestPending,
  requestSuccess,
  requestFail
} from "../../../utils/fetch";
import { initialAccountNumberState } from "./initialAccountNumberState";

export const accountNumberReducer = handleActions(
  {
    // Page Redux datas
    [requestSuccess(
      AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE
    )]: state => ({
      ...state,
      isAccNumberAvailableLoading: false,
      isAccNumberAvailableFailure: false,
      isAccNumberAvailableLoaded: true
    }),
    [requestPending(
      AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE
    )]: state => ({
      ...state,
      isAccNumberAvailableLoading: true,
      isAccNumberAvailableFailure: false,
      isAccNumberAvailableLoaded: false
    }),
    [requestFail(AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE)]: state => ({
      ...state,
      isAccNumberAvailableLoading: false,
      isAccNumberAvailableFailure: true,
      isAccNumberAvailableLoaded: true
    }),

    [requestSuccess(AccountNumberConstatns.CREATE_ACCOUNT)]: state => ({
      ...state,
      createAccountLoading: false,
      createAccountFailure: false,
      createAccountLoaded: true
    }),
    [requestPending(AccountNumberConstatns.CREATE_ACCOUNT)]: state => ({
      ...state,
      createAccountLoading: true,
      createAccountFailure: false,
      createAccountLoaded: false
    }),
    [requestFail(AccountNumberConstatns.CREATE_ACCOUNT)]: state => ({
      ...state,
      createAccountLoading: false,
      createAccountFailure: true,
      createAccountLoaded: true
    })
  },
  initialAccountNumberState()
);
