import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
import signUpSaga from "./signUp/signUpSaga";
import accountNumberSaga from "./accountNumber/accountNumberSaga";
import preferencesSaga from "./preferences/preferencesSaga";
import userDetailsSaga from "./userDetails/userDetailsSaga";
import personalInfoSaga from "./personalInfo/personalInfoSaga";
import commonSaga from "./common/commonSaga";
import reportMerchantSaga from "./ReportMerchant/reportMerchantSaga";
import invitationSaga from "./invitation/invitationSaga";
import claimBonusSaga from "./claimBonus/claimBonusSaga";
import { signUpReducer } from "./signUp/signUpReducer";
import { personalInfoReducer } from "./personalInfo/personalInfoReducer";
import { preferencesReducer } from "./preferences/preferencesReducer";
import { accountNumberReducer } from "./accountNumber/accountNumberReducer";
import { userDetailsReducer } from "./userDetails/userDetailsReducer";
import { commonReducer } from "./common/commonReducer";
import { reportMerchantReducer } from "./ReportMerchant/reportMerchantReducer";
import { invitationReducer } from "./invitation/invitationReducer";
import { claimBonusReducer } from "./claimBonus/claimBonusReducer";
import { identityVerificationReducer } from "./identityVerification/identityVerificationReducer";
import identityVerificationSaga from "./identityVerification/identityVerificationSaga";

export const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    signUpReducer,
    accNumber: accountNumberReducer,
    personalInfo: personalInfoReducer,
    userDetails: userDetailsReducer,
    preferences: preferencesReducer,
    common: commonReducer,
    reportMerchant: reportMerchantReducer,
    invitation: invitationReducer,
    claimBonus: claimBonusReducer,
    identityVerification: identityVerificationReducer
  });

export const rootSaga = function*() {
  yield all([
    signUpSaga(),
    accountNumberSaga(),
    preferencesSaga(),
    personalInfoSaga(),
    userDetailsSaga(),
    commonSaga(),
    reportMerchantSaga(),
    invitationSaga(),
    claimBonusSaga(),
    identityVerificationSaga()
  ]);
};
