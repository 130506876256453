import { handleActions } from "redux-actions";
import { PreferencesConstants } from "./preferencesConstant";

import {
  requestPending,
  requestSuccess,
  requestFail
} from "../../../utils/fetch";
import { initialPreferencesState } from "./initialPreferencesState";

export const preferencesReducer = handleActions(
  {
    [requestSuccess(PreferencesConstants.FETCH_PREFERENCES)]: (
      state,
      action
    ) => ({
      ...state,
      preferences: action.payload || null,
      fetchPreferencesLoading: false,
      fetchPreferencesLoaded: true,
      fetchPreferencesFailure: false
    }),
    [requestPending(PreferencesConstants.FETCH_PREFERENCES)]: (
      state,
    ) => ({
      ...state,
      fetchPreferencesLoading: true,
      fetchPreferencesLoaded: false,
      fetchPreferencesFailure: false
    }),
    [requestFail(PreferencesConstants.FETCH_PREFERENCES)]: (state, action) => ({
      ...state,
      fetchPreferencesLoading: false,
      fetchPreferencesLoaded: true,
      fetchPreferencesFailure: true
    })
  },
  initialPreferencesState()
);
