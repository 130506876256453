import React from "react";
import injectSheet from "react-jss";
import { Tooltip } from "antd";

const styles = {
  root: {
    // boxShadow: "0px 4px 24px rgba(79, 85, 102, 0.2)",
    // borderRadius: "8px",
    cursor: "pointer"
  }
};

const CustomToolTip = ({
  classes,
  children,
  title,
  isHideBoxShadow,
  content,
  placement
}) => {
  return (
    <Tooltip
      title={
        <div className="body">
          {title && <span className="title">{title}</span>}
          {content}
        </div>
      }
      className={!isHideBoxShadow && classes.root}
      placement={placement || "topRight"}
      // color="#4F5566"
    >
      {children}
    </Tooltip>
  );
};

export default injectSheet(styles)(CustomToolTip);
