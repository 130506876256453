import React from "react";
import { connect } from "react-redux";
import CustomizeInput from "../../../../common/components/form/CustomizeInput";
import CustomizedSearchInput from "../../../../common/components/form/CustomizedSearchInput";
import CustomizeDOB from "../../../../common/components/form/CustomizeDOB";
import { fetchCitizenShip } from "../../../../redux/modules/personalInfo/personalInfoActions";
import "./PersonalInfoForm.scss";

const PersonalInfoForm = React.memo(props => {
  const { citizenship, getCitizenShip } = props;
  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const countryRef = React.createRef();
  const [focusDOB, setDOBFocus] = React.useState(0);
  const [focusCountry, setCountryFocus] = React.useState(0);
  const { onChange, defaultFormData, errors, setErrors, isConformScreen } = props;
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    birthday: "",
    nationality: ""
  });

  React.useEffect(() => {
    firstNameRef.current.focus();
    if (citizenship.length <= 0) {
      getCitizenShip();
    }
  }, [citizenship, getCitizenShip]);

  React.useEffect(() => {
    if (defaultFormData) {
      setFormData({ ...defaultFormData });
    }
  }, [setFormData, defaultFormData]);

  const handleFieldChange = React.useCallback(
    (key, value) => {
      if (value.charAt(0) !== " ") {
        const data = {
          ...formData,
          [key]: value
        };
        setFormData({ ...data });
        onChange(data);
        setErrors(prev => ({ ...prev, [key]: "" }));
      }
    },
    [formData, setFormData, onChange, setErrors]
  );

  const handleDobChange = React.useCallback(
    dobData => {
      if (dobData && dobData.day && dobData.month && dobData.year) {
        const data = {
          ...formData,
          birthday: `${dobData.year}-${dobData.month}-${dobData.day}`
        };
        setFormData({ ...data });
        onChange(data);
      } else {
        const data = {
          ...formData,
          birthday: ""
        };
        setFormData({ ...data });
        onChange(data);
      }
      setErrors(prev => ({ ...prev, birthday: "" }));
    },
    [formData, setFormData, onChange, setErrors]
  );

  const citizenShip = React.useMemo(() => {
    if (citizenship.length > 0) {
      return citizenship.map(c => {
        return {
          label: c.citizenship,
          value: c.citizenship_id,
          country: c.name
        };
      });
    }
    return [];
  }, [citizenship]);

  const handleKeyPress = React.useCallback((e, key) => {
    if (e.which === 13) {
      switch (key) {
        case "first_name":
          if (lastNameRef.current && lastNameRef.current.focus)
            lastNameRef.current.focus();
          break;
        case "last_name":
          setDOBFocus(prev => prev + 1);
      }
    }
  }, [lastNameRef, setDOBFocus]);

  React.useEffect(() => {
    if (focusCountry) {
      if (countryRef.current && countryRef.current.focus)
        countryRef.current.focus();
    }
  }, [focusCountry]);

  return (
    <div className="personal-info-form-container">
      <div className="w-100 mb-30">
        <CustomizeInput
          inputRef={firstNameRef}
          value={formData && formData.first_name ? formData.first_name : ""}
          label={"First name"}
          toolTip={{
            title: "First name",
            content:
              "We need your legal name to verify your identity. So no nicknames, sadly—even if they’re really cool. Please enter your personal information as it appears on your official ID."
          }}
          onChange={e => {
            handleFieldChange("first_name", e.target.value);
          }}
          onKeyDown={e => {handleKeyPress(e, "first_name")}}
          error={errors && errors.first_name ? errors.first_name : ""}
        />
      </div>
      <div className="w-100 mb-30">
        <CustomizeInput
          inputRef={lastNameRef}
          value={formData && formData.last_name ? formData.last_name : ""}
          label={"Last name"}
          toolTip={{
            title: "Last name",
            content:
              "Incomplete or fake names will result in your account being canceled."
          }}
          onChange={e => {
            handleFieldChange("last_name", e.target.value);
          }}
          onKeyDown={e => {handleKeyPress(e, "last_name")}}
          error={errors && errors.last_name ? errors.last_name : ""}
        />
      </div>
      <div className="w-100 mb-30">
        <CustomizeDOB
          label={"Date of birth"}
          onChange={handleDobChange}
          error={errors && errors.birthday ? errors.birthday : ""}
          defaultDate={
            defaultFormData && defaultFormData.birthday
              ? defaultFormData.birthday
              : null
          }
          setFocus={focusDOB}
          focusNext={setCountryFocus}
        />
      </div>
      <div className="w-100">
        <CustomizedSearchInput
          disabled={isConformScreen}
          inputRef={countryRef}
          label="Country of residence"
          value={
            citizenShip.filter(v => v.value === formData.nationality)[0] || null
          }
          items={citizenShip}
          renderItem={option => option.label}
          onChange={(event, option) => {
            option && handleFieldChange("nationality", option.value);
          }}
          error={errors && errors.nationality ? errors.nationality : ""}
          filterKeys={["label", "country"]}
        />
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  return {
    citizenship: state.personalInfo.citizenship
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCitizenShip: () => dispatch(fetchCitizenShip())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
