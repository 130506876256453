import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { routePaths } from "../../routes";
import { createAccount } from "../../redux/modules/accountNumber/accountNumberActions";
import { putSignUpUserDetails } from "../../redux/modules/signUp/signUpActions";
import {
  addRestrictionForAppUrl,
  covertToAccNumberFormat,
  displayErrorMessage,
  getFromStorage,
  setIntoStorage
} from "../../utils/common";
import Loader from "../../common/components/Loader";
import Button from "../../common/components/form/Button";
import copyImage from "../../assets/images/copy-gray.svg";
import CopyToClipboard from "../../common/components/CopyToClipboard";
import CheckBoxInput from "../../common/components/form/CheckBoxInput";
import LinkButton from "../../common/components/form/LinkButton";
import SignupHeader from "../../common/components/SignupHeader";
import "./ConfirmAccountNumber.scss";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import StickyFooter from "../../common/components/StickyFooter";

const rules = [
  " A US citizen, or",
  " A US resident alien (green card holder), or",
  " A person meeting the US substantial presence test"
];

export function ConfirmAccountNumber(props) {
  const {
    history,
    createAccountLoading,
    createNewAccount,
    user,
    updateProfile,
    getUserDetails
  } = props;
  const [info, setUserInfo] = useState({
    is_not_usa_person: user.nationality !== "us"
  });
  const [clipBoardValue, setClipBoardValue] = useState("");
  const [error, setErrorMessage] = useState(null);
  const [account_number, setAccountNumber] = useState(null);

  useEffect(() => {
    if (user && user.account_number) {
      addRestrictionForAppUrl(user, history);
      return;
    }
    let accountNo = getFromStorage("accountNumber");
    if (accountNo) {
      onModifiedClipBoard(accountNo);
      setAccountNumber(accountNo);
    }
  }, []);

  const onGoBack = () => history.push(routePaths.accountNumber);

  const onModifiedClipBoard = value => {
    setClipBoardValue(covertToAccNumberFormat(value));
  };

  const onConfirm = () => {
    const onSuccess = () => {
      /* const userDetails = { ...user };
      userDetails.account_number = data.account_number;
      updateProfile(userDetails); */
      setIntoStorage({
        accountNumber: ""
      });
      getUserDetails();
    };
    const onFail = err => setErrorMessage(displayErrorMessage(err));
    createNewAccount({
      data: {
        account_number,
        is_us_person: !info.is_not_usa_person
      },
      onSuccess,
      onFail
    });
  };

  const onChange = e => {
    const userInfo = { ...info };
    const { name, checked } = e.target;
    userInfo[name] = checked;
    setUserInfo(userInfo);
  };

  return (
    <PageLayout divClass="col confirm-acc-number">
      {createAccountLoading && <Loader />}
      <div>
        <SignupHeader
          title="Your Benk Account Number"
          subTitle="You can not change it later."
        />
        <div className="mt-40 content text-center">
          <div className="white-shadow-box">
            <div className="acc-input-value text-center">
              <CopyToClipboard
                mainClassName="d-flex justify-content-center"
                displayValue={clipBoardValue || ""}
                copyValue={covertToAccNumberFormat(clipBoardValue)}
                btnClassName="copy-btn cursor-pointer"
                className="text-center fs-28 fw-500 copy-text"
                copyImage={copyImage}
                iconWidth={20}
              />
            </div>
          </div>
          <div className="mt-60">
            <CheckBoxInput
              id="is_not_usa_person"
              type="checkbox"
              className="fs-14 text-black text-left"
              name="is_not_usa_person"
              checked={info.is_not_usa_person || false}
              onChange={onChange}
              label="I certify that I am not a US Person For tax purposes as I am not:"
            />
            <ul className="rules mt-20">
              {rules.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-40 mb-20">
        <p className="error-text text-center mb-2">{error || ""}</p>
        <div className="fs-12 text-center text-dark-gray mb-20">
          I will inform Benk of any change in circumstances affecting the tax
          status within 30 days of such change.
        </div>
        <Button
          buttonStyle="mt-45 w-100"
          text="Confirm"
          disabled={!info.is_not_usa_person}
          onClick={onConfirm}
        />
        <div className="text-center mt-20 mb-120">
          <LinkButton text="Go back" onClick={onGoBack} />
        </div>
      </div>
      <StickyFooter
        stickyText="Welcome to the demo version of International Banking project powered by Opermont. All data, currencies and amounts are for demo and test only."
      />
    </PageLayout>
  );
}

const mapStateToProps = state => {
  return {
    createAccountLoading: state.accNumber.createAccountLoading
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createNewAccount: payload => dispatch(createAccount(payload)),
    getUserDetails: () => dispatch(fetchUserDetails()),
    updateProfile: user => dispatch(putSignUpUserDetails({ user }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAccountNumber);
