import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { checkIsAccNumberAvailable } from "../../redux/modules/accountNumber/accountNumberActions";
import { routePaths } from "../../routes";
import Loader from "../../common/components/Loader";
import Button from "../../common/components/form/Button";
import AccountInput from "./AccountInput";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import StickyFooter from "../../common/components/StickyFooter";
import { addRestrictionForAppUrl } from "../../utils/common";
import SignupHeader from "../../common/components/SignupHeader";
import "./AccountNumber.scss";

export function AccountNumber(props) {
  const { user, history, isAccNumberLoading } = props;
  const [isAccAvailable, toggleAccAvailable] = useState(false);

  useEffect(() => {
    if (user && user.account_number) {
      addRestrictionForAppUrl(user, history);
    }
  }, []);

  const onConfirm = accConfirm => {
    toggleAccAvailable(accConfirm);
  };

  const onContinue = () => {
    if (isAccAvailable) {
      history.push(routePaths.confirmAccountNumber);
    }
  };

  return (
    <PageLayout divClass="col account-number">
      {isAccNumberLoading && <Loader />}
      <div>
        <SignupHeader
          title="Create your Benk Account Number"
          subTitle="for getting paid by anyone in any currency"
          headerClass="fs-22"
        />
        <div className="mt-100 content text-center">
          <AccountInput
            {...props}
            onContinue={onContinue}
            onConfirm={onConfirm}
          />
        </div>
      </div>
      <div className="mt-20 mb-120">
        <div className="mb-20 fs-12 text-gray text-center info-text">
          Benk Account number is a unique identifier for individuals and
          businesses using Benk. We will add a two-digit security number at
          the end of the desired number. These numbers work together to identify
          your account and ensure that your money ends up in the right place.
        </div>
        <Button
          buttonStyle="w-100"
          text="Continue"
          disabled={!isAccAvailable}
          onClick={onContinue}
        />
      </div>
      <StickyFooter
        stickyText="Welcome to the demo version of International Banking project powered by Opermont. All data, currencies and amounts are for demo and test only."
      />
    </PageLayout>
  );
}

const mapStateToProps = state => {
  return {
    isAccNumberLoading: state.accNumber.isAccNumberAvailableLoading
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkIfAccountAvailable: payload =>
      dispatch(checkIsAccNumberAvailable(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountNumber);
