import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import {
  getFromStorage,
  redirectAccordingToUserInfo,
  removeFromStorage,
  restrictedAccountURl,
  setIntoStorage,
  validate,
  validUserStatus
} from "../../utils/common";
import { ACCESS_TOKEN_TYPE } from "../../constants/constants";
import { routePaths } from "../../routes";
import Loader from "../../common/components/Loader";
import SignupHeader from "../../common/components/SignupHeader";

export function SocialAccVerify({
  history,
  location,
  getUserDetails,
  profileLoaded,
  userDetails
}) {
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const token = params.get("token");
  const otp_uuid = params.get("otp_uuid");
  const status = params.get("status");
  const reason = params.get("reason");
  const restricted_country = params.get("restricted_country");
  const isMfaActive = params.get("is_mfa_active");

  useEffect(() => {
    if (email && status && !validUserStatus.includes(status.toLowerCase())) {
      const redirectTo = restrictedAccountURl(
        status || "",
        reason || "",
        restricted_country || ""
      );
      history.push(redirectTo);
    } else if (email && (token || otp_uuid) && !validate("email", email)) {
      if (otp_uuid) {
        setIntoStorage({
          email,
          otp_uuid
        });
        history.push(routePaths.twoFA);
      } else {
        setIntoStorage({
          email,
          AuthToken: `${ACCESS_TOKEN_TYPE} ${token}`,
          resendOtpAttempt: 0,
          isLoggedIn: true
        });
        const invite_code = getFromStorage("invite_code");
        if (invite_code) {
          removeFromStorage("invite_code");
          getUserDetails({ data: { invite_code } });
        } else {
          getUserDetails();
        }
      }
    } else {
      history.push(routePaths.signUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileLoaded) {
      const redirectTo = redirectAccordingToUserInfo(
        userDetails,
        isMfaActive === "1"
      );
      history.push(redirectTo);
    }
  }, [profileLoaded]);
  return (
    <div className="social-acc-verify">
      <SignupHeader title="Social Account Verification" subTitle="" />
      <div className="content text-center mt-5">
        <Loader />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails.details || {},
    profileFailed: state.userDetails.fetchUserDetailsFailure,
    profileLoaded: state.userDetails.fetchUserDetailsLoaded
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: payload => dispatch(fetchUserDetails(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccVerify);
