import React from "react";
import "./VeriffProcessingModal.scss";
import CustomizeModal from "../../../../common/components/CustomizeModal";
import Loader from "../../../../assets/images/loading-yl.gif";
import LinkButton from "../../../../common/components/form/LinkButton";

const VeriffProcessingModal = React.memo(props => {
  const { visible, isSkippable, onSkip} = props;
  const [showSKip, setShowSkip] = React.useState(false);

  React.useEffect(() => {
    setShowSkip(false);

    setTimeout(() => {
      setShowSkip(true);
    }, 60000);

    return () => setShowSkip(false);
  }, [setShowSkip]);

  return (
    <CustomizeModal
      visible={visible}
      onClose={() => {}}
    >
      <div className="veriff-processing-modal-container">
        <img src={Loader} width={65} height={65}  alt="Loading icon"/>
        <p className="primary-text">Verifying</p>
        <p className="secondary-text">
          Please do not close your browser - this may take a moment.
        </p>
        {isSkippable && showSKip && (
          <div className="mt-20 w-100 text-center">
            <LinkButton text={"Skip for now"} onClick={onSkip} />
          </div>
        )}
      </div>
    </CustomizeModal>
  );
});

export default VeriffProcessingModal;
