import React from "react";
import CustomizeModal from "../../../../common/components/CustomizeModal";
import Button from "../../../../common/components/form/Button/Button";
import warningIcon from "../../../../assets/images/warning-rd.svg";
import LinkButton from "../../../../common/components/form/LinkButton";

const MergeAccountConfirmation = props => {
  const { onClose, visible, onMergeAccount } = props;

  return (
    <CustomizeModal visible={visible} onClose={onClose}>
      <div className="text-center">
        <img src={warningIcon} width={51} height={51} alt="warning" />
      </div>
      <div className="font-wotfard mt-30 fw-600 fs-18 text-center text-black">
        Confirm Merge
      </div>
      <div className="text-center">
        <div className="mt-12 fs-14 text-gray">
          Are you sure you want to merge Benk Accounts?
        </div>
        <div className="mt-40 mb-15">
          <Button text="Yes" onClick={onMergeAccount} />
        </div>
        <div>
          <LinkButton text="No" onClick={onClose} />
        </div>
      </div>
    </CustomizeModal>
  );
};

export default MergeAccountConfirmation;
