import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { filterByKey } from "../../../../utils/common";

const styles = () =>
  createStyles({
    root: {
      backgroundColor: "#F4F4F4",
      borderRadius: "5px 5px 0 0",
      paddingTop: 8,
      "& label": {
        color: "#848FA2",
        marginLeft: 12,
        "&.Mui-error": {
          color: "#ED2939"
        }
      },
      "& label.Mui-focused": {
        color: "#0E8DEA"
      },
      "& .MuiInputLabel-formControl": {
        transform: "translate(0, 12px) scale(1)"
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(0, 1.5px) scale(0.75)"
      },
      "& .Mui-focused": {
        "&:after": {
          borderBottomColor: "#0E8DEA"
        },
        "&.Mui-error:after": {
          borderBottom: "#ED2939",
          borderBottomWidth: 1
        }
      },
      "& .MuiFormHelperText-filled": {
        "&.Mui-error": {
          color: "#ED2939"
        }
      },
      "& .MuiAutocomplete-popupIndicator": {
        marginRight: 5,
        marginTop: -3,
        "&:hover, &:focus, &:active": {
          background: "none"
        }
      },
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
        "&.MuiInputBase-input": {
          textTransform: "capitalize"
        }
      },
      "& .MuiInputBase-root": {
        margin: 0,
        padding: "18px 12px 10px"
      },
      "& .MuiFilledInput-underline.Mui-error:after": {
        borderBottomWidth: "1px"
      },
      "& .MuiInput-underline:after": {
        borderBottomWidth: "1px"
      }
    }
  });

const CustomizedSearchInput = React.memo(props => {
  const {
    inputRef,
    label,
    value,
    items,
    renderItem,
    onChange,
    onClose,
    classes,
    error,
    filterKeys,
    hideErrors,
    renderOption,
    displayExtra,
    disabled
  } = props;

  return (
    <>
      <Autocomplete
        value={value}
        options={items}
        getOptionLabel={renderItem}
        onChange={onChange}
        onClose={onClose}
        blurOnSelect
        disabled={disabled}
        closeIcon={null}
        openOnFocus={true}
        classes={{ root: classes.root }}
        renderInput={params => (
          <>
            {(displayExtra && displayExtra()) || ""}
            <TextField
              inputRef={inputRef}
              error={!!error}
              {...params}
              label={label}
              value={value}
            />
          </>
        )}
        renderOption={option => {
          if (renderOption) {
            return renderOption(option);
          }
          return <span className="text-capitalize">{option.label}</span>;
        }}
        filterOptions={(options, { inputValue }) => {
          return filterByKey(options, inputValue, filterKeys);
        }}
      />
      {error && !hideErrors && <p className="form-error">{error}</p>}
    </>
  );
});

export default withStyles(styles)(CustomizedSearchInput);
