import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { PersonalInfoConstants } from "./personalInfoConstants";

function* getCitizenShip(action) {
  yield call(
    request({
      type: PersonalInfoConstants.FETCH_CITIZEN_SHIP,
      method: "GET",
      url: `info/citizenship`
    }),
    action
  );
}

function* postPersonalInfo(action) {
  yield call(
    request({
      type: PersonalInfoConstants.POST_PERSONAL_INFO,
      method: "POST",
      data: action.payload.data,
      url: `user/setpersonalinformation`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

function* postProfileImage(action) {
  yield call(
    request({
      type: PersonalInfoConstants.UPLOAD_USER_IMAGE,
      method: "POST",
      data: action.payload.data,
      url: `user/uploadpicture`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(PersonalInfoConstants.FETCH_CITIZEN_SHIP, getCitizenShip);
  yield takeLatest(PersonalInfoConstants.POST_PERSONAL_INFO, postPersonalInfo);
  yield takeLatest(PersonalInfoConstants.UPLOAD_USER_IMAGE, postProfileImage);
}
