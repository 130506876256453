import React from "react";
import LinkButton from "../form/LinkButton";
import CautionIcon from "../../../assets/images/caution-gray-sm.svg";
import "./CautionWall.scss";

const CautionWall = React.memo(props => {
  const { title, subTitle, showLength } = props;
  const [readMore, setReadMore] = React.useState(false);
  const [isLess, setIsLess] = React.useState(false);
  const [subTitleText, setSubTitleText] = React.useState([]);

  React.useEffect(() => {
    let initialLength = showLength;
    if (!readMore) {
      setSubTitleText(
        subTitle
          .map(v => {
            if (initialLength) {
              if (v.length < initialLength) {
                initialLength -= v.length;
                return v;
              } else {
                let finalLength = initialLength;
                initialLength = 0;
                setIsLess(true);
                return v.slice(0, finalLength) + "...";
              }
            }
            return false;
          })
          .filter(v => v !== false)
      );
    } else {
      setSubTitleText(subTitle);
    }
  }, [subTitle, setSubTitleText, setIsLess, showLength, readMore]);

  const renderSubtitle = React.useCallback(
    subtitleArr => {
      return (
        <>
          {subtitleArr.map((v, i) => {
            return (
              <p key={i} className="caution-subtitle-item">
                {v}
                {isLess && subtitleArr.length - 1 === i && (
                  <>
                    {" "}
                    <LinkButton
                      text={readMore ? "Read less" : "Read more"}
                      onClick={() => setReadMore(!readMore)}
                      styleClass={`link-btn`}
                    />
                  </>
                )}
              </p>
            );
          })}
        </>
      );
    },
    [setReadMore, isLess, readMore]
  );

  return (
    <div className="caution-wall-container">
      <div className="caution-wall-title">
        <span className="caution-icon">
          <img src={CautionIcon} alt="Caution Icon" />
        </span>
        <span>{title}</span>
      </div>
      <div className="caution-wall-subtitle-title">
        {renderSubtitle(subTitleText)}{" "}
      </div>
    </div>
  );
});

export default CautionWall;
