import React, { useEffect } from "react";
import { connect } from "react-redux";
import { routePaths } from "../../routes";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import Loader from "../../common/components/Loader";
import { clearStorage, redirectAccordingToUserInfo } from "../../utils/common";

const NotFound = props => {
  const { history, profileFailed, userDetails, profileLoading } = props;

  useEffect(() => {
    if (profileFailed) {
      clearStorage();
      history.push(routePaths.signUp);
    }
    if (userDetails && userDetails.email) {
      const redirectTo = redirectAccordingToUserInfo(userDetails);
      history.push(redirectTo);
    }
  }, [profileFailed, userDetails, profileLoading]);

  return (
    <div>
      <Loader />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userDetails:
      state.userDetails.details ||
      (state.signUpReducer.userDetails &&
        (state.signUpReducer.userDetails.user || {})),
    profileLoading: state.userDetails.fetchUserDetailsLoading,
    profileFailed: state.userDetails.fetchUserDetailsFailure
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: payload => dispatch(fetchUserDetails(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
