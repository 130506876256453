import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ACCESS_TOKEN_TYPE,
  MAX_RESEND_OTP_ATTEMPT,
  OTP_INPUT_NUM
} from "../../constants/constants";
import {
  resendOTPRequest,
  verifyEmailOTP
} from "../../redux/modules/signUp/signUpActions";
import {
  displayErrorMessage,
  getFromStorage,
  redirectAccordingToUserInfo,
  removeFromStorage,
  setIntoStorage
} from "../../utils/common";
import SignupHeader from "../../common/components/SignupHeader";
import loading from "../../assets/images/loading.gif";
import FlatOtpInput from "../../common/components/form/FlatOtpInput";
import Loader from "../../common/components/Loader";
import ResendCodeMessage from "../../common/components/ResendCodeMessage";
import StickyFooter from "../../common/components/StickyFooter";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import "./ConfirmEmail.scss";

const ConfirmEmail = props => {
  const {
    history,
    resendReqLoading,
    emailVerificationLoading,
    verifyEmailOTP,
    resendOTPRequest,
    preferences
  } = props;
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otp_uuid, setOtpUUID] = useState("");
  const [noOfAttempt, countNoOfAttempt] = useState(0);
  const [isTimerOn, setTimerToggle] = useState(true);
  const [timeStartFrom, setResendTokenTime] = useState(Date.now());
  const [errMsg, setErrMsg] = useState("");
  const [isResendOtp, handleResendOtp] = useState(true);

  useEffect(() => {
    const email = getFromStorage("email");
    const otp_uuid = getFromStorage("otp_uuid");
    if (email && otp_uuid) {
      setEmail(email);
      setOtpUUID(otp_uuid);
      setIntoStorage({
        otp_uuid: ""
      });
    } else {
      history.goBack();
    }
    setTimeout(() => handleResendOtp(preState => !preState), 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOtpChange = value => {
    setOtp(value);
    if (value && value.toString().length === OTP_INPUT_NUM) {
      const onSuccess = data => {
        if (data.user && data.user.email) {
          if (!data.is_mfa_active) {
            setIntoStorage({
              email: data.user.email,
              isLoggedIn: true,
              AuthToken: `${ACCESS_TOKEN_TYPE} ${data.Token || ""}`,
              otp_uuid: ""
            });
          } else {
            setIntoStorage({
              otp_uuid
            });
          }

          const redirectTo = redirectAccordingToUserInfo(
            data.user,
            data.is_mfa_active
          );
          history.push(redirectTo);
        } else {
          history.goBack();
        }
      };
      const onFail = err => {
        setOtp(null);
        setErrMsg(displayErrorMessage(err));
      };
      const payload = { code: value, otp_uuid };
      const invite_code = getFromStorage("invite_code");
      if (invite_code) {
        removeFromStorage("invite_code");
        payload.invite_code = invite_code;
      }
      verifyEmailOTP({
        data: payload,
        onSuccess,
        onFail
      });
    }
  };

  const onChangeEmail = () => {
    history.goBack();
  };

  const setCodeResendOtp = err => {
    if (err) {
      setErrMsg(err);
      return;
    }
    setErrMsg("");
    countNoOfAttempt(noOfAttempt + 1);
    setIntoStorage({ resendOtpAttempt: noOfAttempt + 1 });
    setTimerToggle(!isTimerOn);
    setResendTokenTime(Date.now());
  };

  const onCodeSend = () => {
    const onSuccess = () => setCodeResendOtp();
    const onFail = err => setCodeResendOtp(displayErrorMessage(err));
    resendOTPRequest({
      data: { otp_uuid },
      onSuccess,
      onFail
    });
  };

  return (
    <PageLayout divClass="col confirm-email">
      {(resendReqLoading || emailVerificationLoading) && <Loader />}
      <div>
        <SignupHeader title="Enter One-Time Password" />
      </div>
      <div className="mt-1 fs-15 text-center text-gray">
        from <span className="text-blue">{email}</span>
      </div>
      <div className="mt-1 fs-15 text-center text-gray">
        Wrong?{" "}
        <span className="text-blue cursor-pointer" onClick={onChangeEmail}>
          Change email
        </span>
      </div>
      <div className="mt-30 fs-13 text-center text-black message-box">
        <div className="text-center mb-2 mt-2">
          <img src={loading} alt="loading" width={21} />
        </div>
        Keep this window open while checking for your OTP.
        <p>Remember to try your spam folder!</p>
      </div>
      <div className="mt-50">
        <FlatOtpInput
          onChange={onOtpChange}
          numInputs={3}
          value={otp || ""}
          maxLength={6}
        />
        <p className="error-text text-center mt-4">{errMsg}</p>
      </div>
      <div className="fs-12 mt-auto mb-150">
        <ResendCodeMessage
          isTimerOn={isTimerOn}
          timeStartFrom={timeStartFrom}
          noOfAttempt={noOfAttempt}
          setTimerToggle={setTimerToggle}
          mainText={
            <div className="mb-20 text-center text-black">
              {noOfAttempt === 0 || !noOfAttempt
                ? "Need another OTP? "
                : "Didn't receive a One-Time Password? "}
              <span
                className={`text-blue cursor-pointer 
                ${(noOfAttempt >= MAX_RESEND_OTP_ATTEMPT ||
                  props.resendOtpRequestLoading ||
                  isResendOtp) &&
                  "disabled"}`}
                onClick={onCodeSend}
              >
                {noOfAttempt === 0 || !noOfAttempt ? "Resend" : "Resend OTP"}
              </span>
            </div>
          }
        />
        <div className="text-gray text-center">
          By proceeding, you agree to TORG’s{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.terms_url || ""}
            target="_blank"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.privacy_url || ""}
            target="_blank"
          >
            Privacy Policy.
          </a>{" "}
          See the{" "}
          <a
            rel="noreferrer"
            className="text-blue link"
            href={preferences.restrictions_url || ""}
            target="_blank"
          >
            Restrictions.
          </a>
        </div>
      </div>
      <StickyFooter
        stickyText="The demo OTP is 111111 please insert it manually or click the Insert OTP."
        btnText="Insert OTP"
        showBtn="true"
        onClick={() => onOtpChange('111111')}
      />
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    emailVerificationLoading: state.signUpReducer.emailOtpVerifyLoading,
    resendReqLoading: state.signUpReducer.resendOtpRequestLoading,
    user:
      state.signUpReducer.userDetails && state.signUpReducer.userDetails.user
        ? state.signUpReducer.userDetails.user
        : {},
    preferences: state.preferences.preferences || {}
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resendOTPRequest: payload => dispatch(resendOTPRequest(payload)),
    verifyEmailOTP: payload => dispatch(verifyEmailOTP(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
