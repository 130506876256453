import React, { useEffect } from "react";
import { clearStorage } from "../../utils/common";
import { routePaths } from "../../routes";
import Loader from "../../common/components/Loader";

export function Logout({ history }) {
  useEffect(() => {
    clearStorage();
    history.push(routePaths.signUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
}

export default Logout;
