export const initialSignUpState = () => ({
  signUpRequestLoading: false,
  signUpRequestFailure: false,
  signUpRequestLoaded: false,
  emailOtpVerifyLoading: false,
  emailOtpVerifyFailure: false,
  emailOtpVerifyLoaded: false,
  mfaOtpVerifyLoading: false,
  mfaOtpVerifyFailure: false,
  mfaOtpVerifyLoaded: false,
  resendOtpRequestLoading: false,
  resendOtpRequestFailure: false,
  resendOtpRequestLoaded: false,
  getSocialRedirectRequestLoading: false,
  getSocialRedirectRequestFailure: false,
  getSocialRedirectRequestLoaded: false,
  fetchSignUpProfileLoading: false,
  fetchSignUpProfileFailure: false,
  fetchSignUpProfileLoaded: false,
  agreeAndContinue: false,
  storeAgreeAndContinueLoading: false,
  storeAgreeAndContinueFailure: false,
  storeAgreeAndContinueLoaded: false,
  cancelSignUpLoading: false,
  cancelSignUpFailure: false,
  cancelSignUpLoaded: false,
  submitSkip01: false,
  generateEmail: false,
  getGenerateEmailLoading: false,
  getGenerateEmailFailure: false,
  getGenerateEmailLoaded: false
});
