import React from "react";
import Div100vh from "react-div-100vh";

const PageLayout = ({ children, divClass }) => (
  <div
    className={`d-flex flex-column justify-content-between ${divClass || ""}`}
  >
    {children}
  </div>
);

export default PageLayout;
