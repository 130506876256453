import React from "react";
import CustomizeModal from "../../../../common/components/CustomizeModal";
import Button from "../../../../common/components/form/Button/Button";
import ShieldIcon from "../../../../assets/images/right-shield-white.svg";
import LinkButton from "../../../../common/components/form/LinkButton";

const ClaimDollarsModal = props => {
  const { onClose, visible, onMergeAccount, onClick } = props;
  // const handleChange = () => {
  //   history.push(routePaths.claimResult);
  // };

  return (
    <CustomizeModal visible={visible} onClose={onClose}>
      <div className="font-wotfard fw-600 fs-18 text-center text-black">
        Restriction on multiple accounts
      </div>
      <div className="text-center">
        <div className="mt-12 fs-15">
          You are allowed to open or have only one account in Benk. In order
          to maintain a safe and trusted environment for all our users, any
          additional account(s) may be suspended.
          <br />
          By proceeding, you are representing that you have understood these
          restrictions.
        </div>
        <div className="mt-20 mb-20">
          <Button
            prependIcon={<img src={ShieldIcon} alt="Shield icon" />}
            text="AGREE AND CONTINUE"
            onClick={onClick}
          />
        </div>
        <div className=" mb-12">
          <LinkButton text="Merge accounts" onClick={onMergeAccount} />
        </div>
      </div>
    </CustomizeModal>
  );
};

export default ClaimDollarsModal;
