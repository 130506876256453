import React, { useEffect, useState } from "react";
import Processing from "../Components/Processing";
import ReceivedMoneyList from "../Components/ReceivedMoneyList";
import SendFreeBonus from "../Components/SendFreeBonus";
import { connect } from "react-redux";
import { fetchClaimBonus } from "../../../redux/modules/claimBonus/claimBonusAction";
import NumberValue from "../../Transactions/Components/NumberValue";
import { routePaths } from "../../../routes";
import "./ClaimResult.scss";
import PageLayout from "../../../common/components/AppLayout/PageLayout";

const ClaimResult = props => {
  const [animationGif, toggleAnimationGif] = useState(false);
  const [claimedPrize, setClaimedPrize] = useState({});
  const [receiveMoneyList, setReceiveMoneyList] = useState([]);
  const { history, getClaimBonus, claimBonusLoaded, user } = props;

  useEffect(() => {
    history.push(`${routePaths.claimResult}?status=processing`);
    const onSuccess = response => {
      setReceiveMoneyList(response.items || []);
      setClaimedPrize(response.config || {});
    };
    const onFail = () => {};
    getClaimBonus({ onSuccess, onFail });
  }, []);

  useEffect(() => {
    if (claimBonusLoaded) {
      history.push(`${routePaths.claimResult}`);
      const animationTimeout = setTimeout(() => {
        toggleAnimationGif(true);
      }, 600);
      return () => {
        clearTimeout(animationTimeout);
      };
    }
  }, [claimBonusLoaded]);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      toggleAnimationGif(false);
    }, receiveMoneyList.length * 3 * 1000);
    return () => {
      clearTimeout(animationTimeout);
    };
  }, [receiveMoneyList]);
  return (
    <PageLayout divClass="container-pad">
      {!claimBonusLoaded ? (
        <Processing />
      ) : (
        <div className="mt-20 dollar-done">
          <div
            className={`${animationGif &&
              "congratulation-card-bg"} congratulation-card animation-card`}
          >
            <div className="font-wotfard fw-600 fs-28 text-black text-center">
              <div>Congratulations!</div>
              <div className="fs-16">
                You now own{" "}
                <NumberValue
                  currency={user.bonus || {}}
                  value={user.bonus.amount || 0}
                  isDecimalNotDisplay
                />
                !
              </div>
            </div>
          </div>
          <div className="mt-50">
            <ReceivedMoneyList receiveMoneyList={receiveMoneyList} />
          </div>
          <SendFreeBonus
            history={history}
            claimedPrize={claimedPrize}
            animationDelay={receiveMoneyList.length}
            user={user}
          />
        </div>
      )}
    </PageLayout>
  );
};

const mapStateToProps = state => {
  return {
    claimBonusLoaded: state.claimBonus.fetchClaimBonusLoaded
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getClaimBonus: payload => dispatch(fetchClaimBonus(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimResult);
