import React from "react";
import "./VeriffFailModal.scss";
import CustomizeModal from "../../../../common/components/CustomizeModal";
import FailedIcon from "../../../../assets/images/icon-failed.svg";
import Button from "../../../../common/components/form/Button/Button";
import LinkButton from "../../../../common/components/form/LinkButton";

const VeriffFailModal = React.memo(props => {
  const { visible, onSkip, isSkippable, onTryAgain } = props;

  return (
    <CustomizeModal
      visible={visible}
      onClose={() => {}}
    >
      <div className="veriff-fail-modal-container">
        <img src={FailedIcon} width={65} height={65} />
        <p className="primary-text">Your verification failed</p>
        <p className="secondary-text">
          We were unable to verify your identity with the information provided. Please verify a photo ID document to continue.
        </p>
        <div className="mt-50 w-100">
          <Button text="Try Verifying Identity Again" onClick={onTryAgain} />
        </div>
        {isSkippable && (
          <div className="mt-20 w-100 text-center">
            <LinkButton text={"Skip for now"} onClick={onSkip} />
          </div>
        )}
      </div>
    </CustomizeModal>
  );
});

export default VeriffFailModal;
