import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getPreferences } from "../../redux/modules/preferences/preferencesActions";
import { deActivateStatusData } from "./AccountStatusData";
import { routePaths } from "../../routes";
import Button from "../../common/components/form/Button";
import HeaderLogo from "../../assets/images/BenkLogo.png";
import Div100vh from "react-div-100vh";
import "./AccountStatus.scss";
import { getFromStorage } from "../../utils/common";

const AccountStatus = props => {
  const { history, location, contact_url, getPreferences } = props;
  const [interComClose, setInterComClose] = React.useState(undefined);

  useEffect(() => {
    if (!contact_url) {
      const onSuccess = () => {};
      const onFail = () => {};
      getPreferences({ onSuccess, onFail });
    }
  }, []);

  React.useEffect(() => {
    return () =>  {
      window.Intercom("hide");
      if (interComClose !== undefined) {
        clearInterval(interComClose);
      }
      let intercomApp = document.getElementsByClassName("intercom-app");
      if(intercomApp[0]) {
        intercomApp[0].style.cssText = "display: none !important";
      }
    }
  }, []);

  const params = new URLSearchParams(location.search);
  let statusURl = params.get("status") || "";
  const restricted_country = getFromStorage("restricted_country") || "";
  statusURl = (statusURl && statusURl.toLowerCase()) || "";

  let accountStatusData = {};
  if (statusURl && deActivateStatusData[statusURl]) {
    accountStatusData = deActivateStatusData[statusURl] || {};
  }

  const onClick = link => {
    window.open(link, "_blank");
  };

  if (!deActivateStatusData[statusURl]) {
    history.push(routePaths.signUp);
    return null;
  }

  const openSupportBot = () => {
    // window.Intercom("show");
    // let tempInterval = setInterval(() => {
    //   let intercomApp = document.getElementsByClassName("intercom-app");
    //   if(intercomApp[0]) {
    //     intercomApp[0].style.cssText = "display: block !important";
    //   }
    //   let iframe = document.querySelector(
    //       'iframe[name="intercom-messenger-frame"]'
    //   );
    //   if (iframe && (iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document))) {
    //     let iframeDocument =
    //         iframe.contentDocument || iframe.contentWindow.document;
    //     let iframeBody = iframeDocument.getElementById("intercom-container-body");
    //     let closeIcon = iframeBody.querySelector("div[aria-label='Close']");

    //     let downloadBtn = iframeBody.getElementsByClassName("intercom-download-transcript-button");

    //     if (downloadBtn && downloadBtn[0]) {
    //       closeIcon.style.cssText = "display: none !important";
    //     } else {
    //       closeIcon.style.cssText = "display: block !important";
    //     }
    //   }
    // }, 700);
    // setInterComClose(tempInterval);
  };

  return (
      <Div100vh className="d-flex flex-column text-center account-status">
          <div>
            <img
            className="account-logo"
                src={HeaderLogo}
                alt="Header Logo"
            />
          </div>
        <div className="mt-50">
          <img
              src={accountStatusData.image}
              width={99}
              height={99}
              alt="Status"
          />
        </div>
        <div className="font-wotfard fw-600 fs-28 mt-30 pb-15">
          {accountStatusData.title || ""}
        </div>
        {statusURl === "suspiciousactivity" && restricted_country && (
            <>
              {/*<div className="restricted-country">*/}
              {/*  <div className="fs-14 fw-400 text-black d-inline">*/}
              {/*    Country you accessed:*/}
              {/*  </div>*/}
              {/*  <div className="fs-14 fw-500 text-black d-inline">*/}
              {/*    {restricted_country}*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="fs-15 mt-15 text-justify">
                Our records indicate that you have attempted to log in to your
                Benk account from a country in which Benk is prohibited by law
                from doing business ({restricted_country}) per the sanctions imposed
                by the U.S. by the U.S. Treasury Department’s Office of Foreign
                Assets Control or other governing bodies. As a result, your account
                has been restricted.
              </div>
            </>
        )}
        {accountStatusData &&
        accountStatusData.details &&
        accountStatusData.details.map((status, index) => (
            <div className="fs-15 mt-15 text-justify" key={index}>
              {status}{" "}
              {accountStatusData.link && (
                  <span className="cursor-pointer text-blue" onClick={() => window.open(accountStatusData.link[index].link, "_blank")}>
                {accountStatusData.link[index].text}
              </span>
              )}
            </div>
        ))}
        {accountStatusData.button && (
            <div className="mt-auto">
              <div className="support-button">
                <Button
                    text={accountStatusData.button.text}
                    className="fs-15 fw-500 w-90"
                    onClick={openSupportBot}
                />
              </div>
            </div>
        )}
      </Div100vh>
  );
};

const mapStateToProps = state => {
  return {
    contact_url:
        (state.preferences &&
            state.preferences.preferences &&
            state.preferences.preferences.contact_url) ||
        ""
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPreferences: payload => dispatch(getPreferences(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatus);
