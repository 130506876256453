import { handleActions } from "redux-actions";
import { PersonalInfoConstants } from "./personalInfoConstants";

import {
  requestPending,
  requestSuccess,
  requestFail
} from "../../../utils/fetch";
import { initialPersonalInfoState } from "./initialPersonalInfoState";

export const personalInfoReducer = handleActions(
  {
    // Page Redux datas
    [requestSuccess(PersonalInfoConstants.FETCH_CITIZEN_SHIP)]: (
      state,
      action
    ) => ({
      ...state,
      citizenship: action.payload.items || [],
      fetchCitizenShipLoading: false,
      fetchCitizenShipFailure: false,
      fetchCitizenShipLoaded: true
    }),
    [requestPending(PersonalInfoConstants.FETCH_CITIZEN_SHIP)]: state => ({
      ...state,
      fetchCitizenShipLoading: true,
      fetchCitizenShipLoaded: false,
      fetchCitizenShipFailure: false
    }),
    [requestFail(PersonalInfoConstants.FETCH_CITIZEN_SHIP)]: state => ({
      ...state,
      fetchCitizenShipLoading: false,
      fetchCitizenShipLoaded: true,
      fetchCitizenShipFailure: true
    }),

    [requestSuccess(PersonalInfoConstants.POST_PERSONAL_INFO)]: state => ({
      ...state,
      personalInfoSubmitted: true,
      postPersonalInfoLoading: false,
      postPersonalInfoFailure: false,
      postPersonalInfoLoaded: true
    }),
    [requestPending(PersonalInfoConstants.POST_PERSONAL_INFO)]: state => ({
      ...state,
      postPersonalInfoLoading: true,
      postPersonalInfoLoaded: false,
      postPersonalInfoFailure: false
    }),
    [requestFail(PersonalInfoConstants.POST_PERSONAL_INFO)]: state => ({
      ...state,
      postPersonalInfoLoading: false,
      postPersonalInfoLoaded: true,
      postPersonalInfoFailure: true
    }),

    [requestSuccess(PersonalInfoConstants.UPLOAD_USER_IMAGE)]: state => ({
      ...state,
      uploadImageLoading: false,
      uploadImageFailure: false,
      uploadImageLoaded: true
    }),
    [requestPending(PersonalInfoConstants.UPLOAD_USER_IMAGE)]: state => ({
      ...state,
      uploadImageLoading: true,
      uploadImageLoaded: false,
      uploadImageFailure: false
    }),
    [requestFail(PersonalInfoConstants.UPLOAD_USER_IMAGE)]: state => ({
      ...state,
      uploadImageLoading: false,
      uploadImageLoaded: true,
      uploadImageFailure: true
    })
  },
  initialPersonalInfoState()
);
