import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import LinkButton from "../../common/components/form/LinkButton";
import Button from "../../common/components/form/Button/Button";
import SignupHeader from "../../common/components/SignupHeader";
import ShieldIcon from "../../assets/images/right-shield-white.svg";
import PersonalInfoForm from "../PersonalInfo/components/PersonalInfoForm";
import {
  clearStorage,
  displayErrorMessage,
  formValidate
} from "../../utils/common";
import CautionWall from "../../common/components/CautionWall";
import StickyFooter from "../../common/components/StickyFooter";
import { postPersonalInfo } from "../../redux/modules/personalInfo/personalInfoActions";
import { setIdentitySkip } from "../../redux/modules/identityVerification/identityVerificationActions";
import Loader from "../../common/components/Loader";
import { routePaths } from "../../routes";
import {
  cancelSignup,
  putSignUpUserDetails,
  submitSkip01
} from "../../redux/modules/signUp/signUpActions";
import "./Skip01.scss";

const cautionSubtitle = [
  // eslint-disable-next-line max-len
  "Benk is a closed-loop system (having a relationship with both the sender and receiver) that allows us to identify suspicious activity more easily than competing systems.",
  // eslint-disable-next-line max-len
  "Benk conducts a global AML/CTF and Sanctions risk assessment consistent with Financial Action Task Force (FATF) guidance to identify, assess and understand the ML/TF risks Benk faces. This is consistent with a risk-based approach (RBA) which impacts global policy decision-making and implementation of program elements.",
  // eslint-disable-next-line max-len
  "Benk's Customer Due Diligence program collects certain identity details at sign-up while remaining relatively frictionless. Once certain thresholds are met, in compliance with relevant market regulation, Benk will subject users to additional KYC requirements for identity verification.",
  // eslint-disable-next-line max-len
  "Benk screens accounts and transaction history on a nightly basis, covering the entire customer base. We cross-reference our information against a variety of lists from regulators, governments and more (OFAC's Specially Designated Nationals list, UN Security Council sanctions list, Commission de Surveillance du Secteur Financier in the EU, etc.)."
];

const Skip01 = React.memo(props => {
  const {
    history,
    postPersonalInfo,
    user,
    cancelSignup,
    finishSkip01,
    // submitSkip01,
    updateProfile,
    setIdentitySkip
  } = props;
  const [defaultData, setDefaultData] = React.useState(null);
  const [personalInfo, setPersonalInfo] = React.useState({
    first_name: "",
    last_name: "",
    birthday: "",
    nationality: ""
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [formError, setFormError] = React.useState({});

  // React.useEffect(() => {
  //   if (submitSkip01) {
  //     history.push(routePaths.skip02);
  //   }
  // }, [submitSkip01, history]);

  React.useEffect(() => {
    if (!user.is_verify && user.need_verify_country) {
      setIdentitySkip();
      history.push(routePaths.identityVerification);
      return;
    }
    if (user && user.personal_info_step) {
      if (user.personal_info_step > 1) {
        history.push(routePaths.skip02);
      }
    }
  }, [user, history]);

  React.useEffect(() => {
    if (user && user.first_name) {
      setDefaultData({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        nationality: user.nationality || "",
        birthday: user.birthday || ""
      });
      setPersonalInfo({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        nationality: user.nationality || "",
        birthday: user.birthday || ""
      });
    }
  }, [user, setDefaultData, setPersonalInfo]);

  const handleFormChange = React.useCallback(
    data => {
      setPersonalInfo(data);
    },
    [setPersonalInfo]
  );

  const handleFormSubmit = React.useCallback(() => {
    const formErr = formValidate(personalInfo);
    setFormError(formErr);
    if (_.isEmpty(formErr)) {
      setLoading(true);
      setError("");
      const onSuccess = res => {
        setLoading(false);
        finishSkip01();
        updateProfile(res.user);
        // if (!res.user.is_verify && res.user.need_verify_country) {
        //   history.push(routePaths.identityVerification);
        //   updateProfile(res.user);
        // } else {
        //   history.push(routePaths.skip02);
        // }
      };
      const onFail = err => {
        setError(displayErrorMessage(err));
        setLoading(false);
      };
      postPersonalInfo({ data: personalInfo, onFail, onSuccess });
    }
  }, [
    setFormError,
    personalInfo,
    postPersonalInfo,
    setLoading,
    setError,
    history,
    finishSkip01
  ]);

  const handleCancelSignup = React.useCallback(() => {
    setLoading(true);
    const onSuccess = () => {
      // setLoading(false);
      // clearStorage();
      // history.push(routePaths.signUp);
    };
    const onFail = () => {
      setError("Something went wrong.");
      setLoading(false);
    };
    cancelSignup({ onSuccess, onFail });
  }, [cancelSignup, history, setLoading, setError]);

  return (
    <div className="skip01-container container-pad">
      {loading && <Loader />}
      <div className="header-container">
        <SignupHeader
          title="Please confirm or correct this information"
          subTitle="You can not change it later."
        />
      </div>
      <div className="form-container">
        <PersonalInfoForm
          isConformScreen={true}
          onChange={handleFormChange}
          errors={formError}
          setErrors={setFormError}
          defaultFormData={defaultData}
        />
      </div>
      <div className="footer-container">
        {error && <div className="error-text">{error}</div>}
        <div className="signup-footer-top-text">
          I certify that I am NOT a U.S. person. I also certify that the
          information submitted in this application is true and correct to the
          best of my knowledge.
        </div>
        <div className="btn-container">
          <Button
            prependIcon={<img src={ShieldIcon} alt="Shield icon" />}
            text="Confirm"
            onClick={handleFormSubmit}
          />
          <LinkButton
            text="Cancel sign up"
            onClick={handleCancelSignup}
            styleClass="mt-20"
          />
        </div>
        <div className="skip-footer-end-text mb-50">
          <CautionWall
            title="Important Information"
            subTitle={cautionSubtitle}
            showLength={107}
          />
        </div>
      </div>
      <StickyFooter 
        stickyText="Welcome to the demo version of International Banking project powered by Benk. All data, currencies and amounts are for demo and test only."
      />
    </div>
  );
});

const mapStateToProps = state => {
  return {
    submitSkip01: state.signUpReducer.submitSkip01
  };
};
const mapDispatchToProps = dispatch => {
  return {
    postPersonalInfo: payload => dispatch(postPersonalInfo(payload)),
    cancelSignup: payload => dispatch(cancelSignup(payload)),
    finishSkip01: () => dispatch(submitSkip01()),
    setIdentitySkip: () => dispatch(setIdentitySkip()),
    updateProfile: user => dispatch(putSignUpUserDetails({ user }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Skip01);
