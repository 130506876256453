import React from "react";
import { Helmet } from "react-helmet";

const MetaHeader = ({ image, keywords, title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords || ""} />
    <meta itemProp="image" content={`${window.location.origin}${image}`} />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={`${window.location.origin}${image}`} />
  </Helmet>
);

export default MetaHeader;
