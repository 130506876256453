import React from "react";
import Button from "../../../../common/components/form/Button/Button";
import { invite } from "../../../../routes";
import NumberValue from "../../../Transactions/Components/NumberValue";
import { redirectToLoginApp } from "../../../../utils/common";
const SendFreeBonus = ({ animationDelay, user }) => {
  return (
    <div className="mt-15 mb-20">
      <div
        className="box-shadow text-center text-white animation-card"
        style={{ animationDelay: `${animationDelay * 3}s` }}
      >
        <div>
          <div className="fw-bold fs-18">
            Why not send a{" "}
            <NumberValue
              currency={user.bonus || {}}
              value={user.bonus.amount || 0}
              isDecimalNotDisplay
            />{" "}
            free bonus to loved ones?
          </div>
          <div className="fs-14 mt-10">
            They&apos;ll get a{" "}
            <NumberValue
              currency={user.bonus || {}}
              value={user.bonus.amount || 0}
              isDecimalNotDisplay
            />{" "}
            free bonus, and you&apos;ll get{" "}
            <NumberValue
              currency={user.bonus || {}}
              value={user.bonus.amount || 0}
              isDecimalNotDisplay
            />{" "}
            for each one, too.
          </div>
          <div className="mt-30 yellow-btn">
            <Button
              text="Send now"
              className="fs-15 fw-bold"
              onClick={() => {
                redirectToLoginApp(invite);
              }}
            />
          </div>
          <div className="mt-20 fw-500 fs-14 footer-end-text">
            <span
              className="cursor-pointer"
              onClick={() => {
                redirectToLoginApp();
              }}
            >
              Not now
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendFreeBonus;
