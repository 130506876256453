import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const FlatCssTextField = withStyles({
  root: {
    width: 170,
    "& label": {
      color: "#848FA2",
      "&.Mui-error": {
        color: "#ED2939"
      }
    },
    "& label.Mui-focused": {
      color: "#0E8DEA"
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        borderBottomColor: "#0E8DEA"
      },
      "&.Mui-error:after": {
        borderBottomColor: "#ED2939"
      }
    },
    "& .MuiFormHelperText-filled": {
      "&.Mui-error": {
        color: "#ED2939"
      }
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#FFF",
      borderRadius: "5px 5px 0 0",
      width: 170,
      "& .MuiFilledInput-input": {
        textAlign: "center",
        fontSize: 34,
        fontWeight: 500,
        padding: 0,
        paddingBottom: 5
      }
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0
    }
  }
})(TextField);

const FlatInput = props => {
  const {
    label,
    value,
    onChange,
    placeholder,
    error,
    inputType,
    maxLength,
    onPaste,
    onBlur,
    onKeyDown,
    hideError
  } = props;
  const inputRef = React.createRef();

  useEffect(() => inputRef.current.focus(), []);

  return (
    <div className="flat-input text-center">
      <FlatCssTextField
        error={!!error}
        helperText={hideError ? "" : error}
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur || (() => {})}
        onKeyDown={onKeyDown || (() => {})}
        onPaste={onPaste || (() => {})}
        variant="filled"
        inputProps={{ maxLength: maxLength }}
        type={inputType}
        fullWidth
        autoFocus
        inputRef={inputRef}
      />
    </div>
  );
};

export default FlatInput;
