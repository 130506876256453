export const initialIdentityVerificationState = () => ({
  fetchUserInfoLoading: false,
  fetchUserInfoFailure: false,
  fetchUserInfoLoaded: false,
  userVeriffStatus: null,
  userName: "",
  getVeriffUrlRequestLoading: false,
  getVeriffUrlRequestFailure: false,
  getVeriffUrlRequestLoaded: false,
  veriffUrl: "",
  setSkipIdentityLoading: false,
  setSkipIdentityFailure: false,
  setSkipIdentityLoaded: false,
  skipIdentity: false
});
