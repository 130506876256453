import React from "react";
import Button from "react-bootstrap/Button";

import "./Button.scss";
import clsx from "clsx";

const CustomButton = props => {
  const {
    text,
    onClick,
    disabled,
    prependIcon,
    appendIcon,
    buttonStyle,
    textLeftAlign
  } = props;
  return (
    <Button
      variant="dark"
      size="lg"
      className={clsx(
        "custom-btn-container",
        buttonStyle,
        textLeftAlign && "text-align-left",
        disabled && "pointer-none"
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {prependIcon && <span className="btn-prepend-icon">{prependIcon}</span>}
      <span className={clsx("btn-text", disabled && "disable-btn-text")}>
        {text}
      </span>
      {appendIcon && <span className="btn-append-icon">{appendIcon}</span>}
    </Button>
  );
};

export default CustomButton;
