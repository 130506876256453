import { createAction } from "redux-actions";

import { AccountNumberConstatns } from "./accountNumberConstants";

export const checkIsAccNumberAvailable = createAction(
  AccountNumberConstatns.IS_ACC_NUMBER_AVAILABLE
);

export const createAccount = createAction(
  AccountNumberConstatns.CREATE_ACCOUNT
);
