const keyMirror = require("keymirror");

export const identityVerificationConstants = keyMirror({
  GET_USER_INFO: undefined,
  GET_VERIFF_URL: undefined,
  SET_SKIP_IDENTITY: undefined,
  SET_IDENTITY_SUBMITTED: undefined,
  SET_IDENTITY_SKIP: undefined,
  CLEAR_VERIFF_SESSION: undefined
});
