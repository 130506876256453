import { handleActions } from "redux-actions";
import { initialIdentityVerificationState } from "./initialIdentityVerificationState";
import { identityVerificationConstants } from "./identityVerificationConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";
import { parseUserVeriffStatus } from "../../../pages/IdentityVerification/utils";
import { parseUserFullName } from "../../../utils/common";

export const identityVerificationReducer = handleActions(
  {
    [requestSuccess(identityVerificationConstants.GET_USER_INFO)]: (
      state,
      action
    ) => ({
      ...state,
      userVeriffStatus: parseUserVeriffStatus(action.payload.user),
      userName: parseUserFullName(action.payload.user),
      fetchUserInfoLoading: false,
      fetchUserInfoFailure: false,
      fetchUserInfoLoaded: true
    }),
    [requestPending(identityVerificationConstants.GET_USER_INFO)]: state => ({
      ...state,
      fetchUserInfoLoading: true,
      fetchUserInfoLoaded: false,
      fetchUserInfoFailure: false
    }),
    [requestFail(identityVerificationConstants.GET_USER_INFO)]: state => ({
      ...state,
      userVeriffStatus: null,
      userName: "",
      fetchUserInfoLoading: false,
      fetchUserInfoLoaded: true,
      fetchUserInfoFailure: true
    }),
    [requestSuccess(identityVerificationConstants.GET_VERIFF_URL)]: (
      state,
      action
    ) => ({
      ...state,
      veriffUrl:
        (action.payload.veriff && action.payload.veriff.verification && action.payload.veriff.verification.url) ? action.payload.veriff.verification.url : "",
      getVeriffUrlRequestLoading: false,
      getVeriffUrlRequestFailure: false,
      getVeriffUrlRequestLoaded: true
    }),
    [requestPending(identityVerificationConstants.GET_VERIFF_URL)]: state => ({
      ...state,
      getVeriffUrlRequestLoading: true,
      getVeriffUrlRequestLoaded: false,
      getVeriffUrlRequestFailure: false
    }),
    [requestFail(identityVerificationConstants.GET_VERIFF_URL)]: state => ({
      ...state,
      getVeriffUrlRequestLoading: false,
      getVeriffUrlRequestLoaded: true,
      getVeriffUrlRequestFailure: true
    }),
    [identityVerificationConstants.SET_IDENTITY_SUBMITTED]: state => ({
      ...state,
      skipIdentity: true
    }),
    [identityVerificationConstants.SET_IDENTITY_SKIP]: state => ({
      ...state,
      skipIdentity: false
    }),
    [requestSuccess(identityVerificationConstants.SET_SKIP_IDENTITY)]: (state, action) => ({
      ...state,
      skipIdentity: action.payload.skipidentify || false,
      setSkipIdentityLoading: false,
      setSkipIdentityFailure: false,
      setSkipIdentityLoaded: true
    }),
    [requestPending(identityVerificationConstants.SET_SKIP_IDENTITY)]: state => ({
      ...state,
      setSkipIdentityLoading: true,
      setSkipIdentityFailure: false,
      setSkipIdentityLoaded: false
    }),
    [requestFail(identityVerificationConstants.SET_SKIP_IDENTITY)]: state => ({
      ...state,
      setSkipIdentityLoading: false,
      setSkipIdentityFailure: true,
      setSkipIdentityLoaded: true
    }),
    [identityVerificationConstants.CLEAR_VERIFF_SESSION]: state => ({
      ...state,
      veriffUrl: "",
      userVeriffStatus: null,
      userName: "",
    })
  },
  initialIdentityVerificationState()
);
