import React from "react";
import { connect } from "react-redux";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import SignupHeader from "../../common/components/SignupHeader";
import RightIcon from "../../assets/images/right-vector-black.svg";
import ModuloIcon from "../../assets/images/modulo-vector-black.svg";
import BedgeIcon from "../../assets/images/badge-vector-black.svg";
import InfiniteIcon from "../../assets/images/infinite-vector-black.svg";
import LinkButton from "../../common/components/form/LinkButton";
import { routePaths } from "../../routes";
import { fetchUserDetails } from "../../redux/modules/userDetails/userDetailsActions";
import Loader from "../../common/components/Loader";
import { config } from "../../utils/config";
import ShieldIcon from "../../assets/images/right-shield-white.svg";
import "./IdentityVerification.scss";
import {
  displayErrorMessage,
  redirectToLoginApp,
  veriffVerified,
  veriffRejected,
  needVerification,
  veriffSubmitted,
  waitListUser
} from "../../utils/common";
import Button from "../../common/components/form/Button/Button";
import StickyFooter from "../../common/components/StickyFooter";
import {
  getUserInfo,
  fatchVeriffUrl,
  setIdentitySubmitted,
  setSkipIdentity,
  clearVeriffSession
} from "../../redux/modules/identityVerification/identityVerificationActions";
import VeriffProcessingModal from "./components/VeriffProcessingModal";
import VeriffSuccessModal from "./components/VeriffSuccessModal";
import VeriffFailModal from "./components/VeriffFailModal";
import { storeAgreeAndContinue } from "../../redux/modules/signUp/signUpActions";

const futureList = {
  title: "Unlock these amazing features:",
  listData: [
    {
      icon: RightIcon,
      value: "Identity Verification Badge."
    },
    {
      icon: ModuloIcon,
      value: "Interest account."
    },
    {
      icon: BedgeIcon,
      value: "Credit account."
    },
    {
      icon: InfiniteIcon,
      value: "Unlimited transfers."
    }
  ]
};

const IdentityVerification = React.memo(props => {
  const {
    history,
    userDetails,
    getUserDetails,
    setSkipIdentity,
    isSkipIdentity,
    identitySubmitted,
    getVeriffUrl,
    veriffUrl,
    getUserStatus,
    veriffStatus,
    userName,
    clearVeriffSession,
    skipFinalStep,
    user
  } = props;

  const [waiter, setWaiter] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [veriffProcessing, setVeriffProcessing] = React.useState(false);
  const [veriffSuccess, setVeriffSuccess] = React.useState(false);
  const [veriffFail, setVeriffFail] = React.useState(false);
  const [error, setError] = React.useState("");
  const [veriffError, setVeriffError] = React.useState(false);
  const [interComClose, setInterComClose] = React.useState(undefined);

  React.useEffect(() => {
    if (isSkipIdentity) {
      history.push(routePaths.skip01);
    }
  }, [isSkipIdentity, history]);

  React.useEffect(() => {
    if (veriffStatus === veriffVerified) {
      clearInterval(waiter);
      setVeriffProcessing(false);
      setVeriffSuccess(true);
      if (needVerification.includes(user.user_country_type)) {
        if (user.user_country_type === waitListUser) {
          setTimeout(() => {
            window.location.href = routePaths.waitList;
          }, 3000);
        } else {
          setTimeout(() => {
            redirectToLoginApp(null, user, history);
          }, 3000);
        }
      } else {
        const onSuccess = () => {
          setTimeout(() => {
            redirectToLoginApp(null, user, history);
          }, 3000);
        };
        const onFail = err => {
          setError(displayErrorMessage(err));
          setVeriffSuccess(false);
        };

        const data = {
          personal_info_step: 2
        };
        skipFinalStep({ data, onSuccess, onFail })
      }
    }
    if (veriffRejected.includes(veriffStatus)) {
      clearInterval(waiter);
      setVeriffProcessing(false);
      setVeriffFail(true);
    }
  }, [waiter, veriffStatus, history, setVeriffProcessing, setVeriffSuccess, setVeriffFail, user, skipFinalStep, history]);

  const waitForConfirmation = React.useCallback(() => {
      const waitertemp = setInterval(() => {
        getUserStatus();
      }, 3000);
      setWaiter(waitertemp);
  }, [getUserStatus, setWaiter]);

  React.useEffect(() => {
    if (userDetails && userDetails.latest_veriff_status) {
      if (userDetails.latest_veriff_status === veriffSubmitted) {
        setVeriffProcessing(true);
        waitForConfirmation();
      } else if (veriffRejected.includes(userDetails.latest_veriff_status)) {
        setVeriffFail(true);
      }
    }
  }, [userDetails, setVeriffProcessing, waitForConfirmation, setVeriffFail]);

  const loadVeriff = React.useCallback(
    url => {
      createVeriffFrame({
        url,
        onEvent: function(msg) {
          switch (msg) {
            case MESSAGES.FINISHED: {
              setVeriffProcessing(true);
              waitForConfirmation();
              break;
            }
            case MESSAGES.CANCELED: {
              setVeriffError(true);
              break;
            }
            default:
              break;
          }
        }
      });
    },
    [getUserDetails, identitySubmitted, history, user, setVeriffProcessing, setVeriffError]
  );

  const handleContinueClick = React.useCallback(() => {
    setVeriffError(false);
    setLoading(true);
    if (veriffUrl === "") {
      const onSuccess = data => {
        if (
          data.veriff &&
          data.veriff.verification &&
          data.veriff.verification.url
        ) {
          loadVeriff(data.veriff.verification.url);
        } else {
          setError("something went wrong please try again");
        }
        setLoading(false);
      };
      const onFail = err => {
        setError(displayErrorMessage(err));
        setLoading(false);
      };
      getVeriffUrl({ onSuccess, onFail });
    } else {
      setTimeout(() => {
        loadVeriff(veriffUrl);
        setLoading(false);
      }, 500);
    }
  }, [getVeriffUrl, loadVeriff, setError, veriffUrl, setVeriffError]);

  const handleSkipClick = React.useCallback(timeout => {
    setVeriffError(false);
    setLoading(true);
    const data = {
      skipidentify: true
    };
    const onSuccess = () => setLoading(false);
    const onFail = err => {
      setError(displayErrorMessage(err));
      setLoading(false);
    };
    if (timeout) {
      setTimeout(() => {
        setSkipIdentity({ data, onSuccess, onFail });
      }, timeout);
    } else {
      setSkipIdentity({ data, onSuccess, onFail });
    }
  }, [setSkipIdentity, setError, setLoading, setVeriffError]);

  const handleTryAgainClick = React.useCallback(() => {
    clearVeriffSession();
    setTimeout(() => {
      setVeriffFail(false);
      handleContinueClick();
    }, 600);
  }, [clearVeriffSession, setVeriffFail]);

  const handleVeriffProcessingSkip = React.useCallback(() => {
    clearInterval(waiter);
    setVeriffProcessing(false);
    handleSkipClick(0);
  }, [setVeriffProcessing, waiter]);

  const handleVeriffFailSkip = React.useCallback(() => {
    clearVeriffSession();
    setVeriffFail(false);
    handleSkipClick(0);
  }, [setVeriffFail, clearVeriffSession]);

  const openSupportBot = () => {
    // window.Intercom("show");
    // let tempInterval = setInterval(() => {
    //   let intercomApp = document.getElementsByClassName("intercom-app");
    //   if(intercomApp[0]) {
    //     intercomApp[0].style.cssText = "display: block !important";
    //   }
    //   let iframe = document.querySelector(
    //     'iframe[name="intercom-messenger-frame"]'
    //   );
    //   if (iframe && (iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document))) {
    //     let iframeDocument =
    //       iframe.contentDocument || iframe.contentWindow.document;
    //     let iframeBody = iframeDocument.getElementById("intercom-container-body");
    //     let closeIcon = iframeBody.querySelector("div[aria-label='Close']");

    //     let downloadBtn = iframeBody.getElementsByClassName("intercom-download-transcript-button");

    //     if (downloadBtn && downloadBtn[0]) {
    //       closeIcon.style.cssText = "display: none !important";
    //     } else {
    //       closeIcon.style.cssText = "display: block !important";
    //     }
    //   }
    // }, 700);
    // setInterComClose(tempInterval);
  };

  // React.useEffect(() => {
  //   return () =>  {
  //     window.Intercom("hide");
  //     if (interComClose !== undefined) {
  //       clearInterval(interComClose);
  //     }
  //     let intercomApp = document.getElementsByClassName("intercom-app");
  //     if(intercomApp[0]) {
  //       intercomApp[0].style.cssText = "display: none !important";
  //     }
  //   }
  // }, []);

  return (
    <div className="container-pad identity-verification">
      <VeriffProcessingModal
        visible={veriffProcessing}
        isSkippable={user && !(needVerification.includes(user.user_country_type) || user.need_verify_country)}
        onSkip={handleVeriffProcessingSkip}
      />
      <VeriffSuccessModal visible={veriffSuccess} userName={userName} />
      <VeriffFailModal
        visible={veriffFail}
        isSkippable={user && !(needVerification.includes(user.user_country_type) || user.need_verify_country)}
        onSkip={handleVeriffFailSkip}
        onTryAgain={handleTryAgainClick}
      />
      {(!user || loading) && <Loader />}
      <div className="verification-sub-container">
        <SignupHeader
          title="Verify your identity"
          subTitle="Takes a few minutes"
          linkText="Skip for now"
          onLinkClick={() => handleSkipClick(1000)}
          hideSkip={user && (needVerification.includes(user.user_country_type) || user.need_verify_country)}
        />
        <div className="header-info-text">
          Please prepare your passport or ID card and choose the verification
          assistance option.
        </div>
        <div className="features-list-container">
          <div className="feature-list-title">{futureList.title}</div>
          <div className="feature-list-item-container">
            {futureList.listData.map((v, i) => (
              <div key={i} className="feature-list-item">
                <span className="feature-list-icon">
                  <img src={v.icon} alt="Logo icon" />
                </span>
                <span className="feature-list-text">{v.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-container">
        {veriffError && (
          <div className="error-text text-center mb-1">
            Unfortunately, we were unable to verify your identity. Please try
            submitting your identity documentation again by clicking on{" "}
            <LinkButton
              text="Continue"
              onClick={handleContinueClick}
              styleClass="error-link-btn"
            />{" "}
            or{" "}
            <LinkButton
              text="Chat&nbsp;with&nbsp;us"
              onClick={openSupportBot}
              styleClass="error-link-btn"
            />{" "}
            if the problem persists.
          </div>
        )}
        {error && <div className="error-text text-center">{error}</div>}
        <div className="signup-footer-top-text">
          This information will be verified using the Trusted Identity
          Verification services,{" "}
          <LinkButton
            text="Veriff"
            onClick={() => window.open(config.VERIFF_URL, "_blank")}
            styleClass="link-btn"
          />
          , and/or{" "}
          <LinkButton
            text="Onfido"
            onClick={() => window.open(config.ONFIDO_URL, "_blank")}
            styleClass="link-btn"
          />
          .
        </div>
        <div className="btn-container">
          <Button
            prependIcon={<img src={ShieldIcon} alt="Shield icon" />}
            text="Continue"
            onClick={handleContinueClick}
          />
        </div>
        <div className="signup-footer-end-text mt-20 text-center mb-120">
          If you have any technical issues please{" "}
          <a className="mail-link" onClick={() => window.Intercom("show")}>
            chat with us.
          </a>
          {/*<LinkButton*/}
          {/*  text="mailto:support@aespen.com"*/}
          {/*  onClick={() => {}}*/}
          {/*  styleClass="link-btn-end"*/}
          {/*/>*/}
          <div className="footer-icons">
            {/*<img src={OnfidoLogo} alt="Onfido logo" className="mr-3" />*/}
            {/*<img src={PassbaseLogo} alt="Passbase logo" />*/}
          </div>
        </div>
      </div>
      <StickyFooter
        stickyText="Welcome to the demo version of International Banking project powered by Opermont. You don’t have to do the verification please skip the step and proceed."
        btnText="Skip"
        showBtn="true"
        onClick={() => handleSkipClick(1000)}
      />
    </div>
  );
});

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails.details,
    isSkipIdentity: state.identityVerification.skipIdentity,
    veriffUrl: state.identityVerification.veriffUrl,
    veriffStatus: state.identityVerification.userVeriffStatus,
    userName: state.identityVerification.userName
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: () => dispatch(fetchUserDetails()),
    setSkipIdentity: payload => dispatch(setSkipIdentity(payload)),
    identitySubmitted: () => dispatch(setIdentitySubmitted()),
    getVeriffUrl: payload => dispatch(fatchVeriffUrl(payload)),
    getUserStatus: payload => dispatch(getUserInfo(payload)),
    clearVeriffSession: () => dispatch(clearVeriffSession()),
    skipFinalStep: payload => dispatch(storeAgreeAndContinue(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentityVerification);
