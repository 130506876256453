const envType = process.env.REACT_APP_ENV || "development";

const types = {
  development: {
    BASE_URL: "https://devstg.benk.com/",
    APP_DOMAIN: "https://dev-preapp.benk.com",
    INTERCOM_APP_ID: "mtnm3ihr",
    PASSBASE_URL: "https://passbase.com/",
    VERIFF_URL: "https://www.veriff.com/product",
    ONFIDO_URL: "https://onfido.com/",
    LEGAL_PAGE_URL: "http://localhost/intl/legal"
  },
  staging: {
    BASE_URL: "https://preapi.eusandbox.com/",
    // APP_DOMAIN: "https://preapp.eusandbox.com",
    APP_DOMAIN: "https://dev-preapp.benk.com/",
    INTERCOM_APP_ID: "mtnm3ihr",
    PASSBASE_URL: "https://passbase.com/",
    VERIFF_URL: "https://www.veriff.com/product",
    ONFIDO_URL: "https://onfido.com/",
    LEGAL_PAGE_URL: "https://prelanding.eusandbox.com/intl/legal"
  },
  production: {
    BASE_URL: "https://endpoint.neobenk.com/",
    // APP_DOMAIN: "https://app.neobenk.com",
    APP_DOMAIN: "https://dev-preapp.benk.com/",
    INTERCOM_APP_ID: "mtnm3ihr",
    PASSBASE_URL: "https://passbase.com/",
    VERIFF_URL: "https://www.veriff.com/product",
    ONFIDO_URL: "https://onfido.com/",
    LEGAL_PAGE_URL: "https://neobenk.com/intl/legal"
  }
};

export const config = types[envType];
